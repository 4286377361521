/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
:root {
  --theme-default: hsl(0, 0%, 90%);
  --theme-default-h: 0;
  --theme-default-s: 0%;
  --theme-default-l: 90%;
  --theme-default-light: hsl(0, 0%, 94%);
  --theme-default-light-h: 0;
  --theme-default-light-s: 0%;
  --theme-default-light-l: 94%;
  --theme-default-lighter: hsl(0, 0%, 95%);
  --theme-default-lighter-h: 0;
  --theme-default-lighter-s: 0%;
  --theme-default-lighter-l: 95%;
  --theme-default-dark: hsl(0, 0%, 86%);
  --theme-default-dark-h: 0;
  --theme-default-dark-s: 0%;
  --theme-default-dark-l: 86%;
  --theme-default-darker: hsl(0, 0%, 20%);
  --theme-default-darker-h: 0;
  --theme-default-darker-s: 0%;
  --theme-default-darker-l: 20%;
  --theme-primary: hsl(156, 100%, 36.9%);
  --theme-primary-h: 156;
  --theme-primary-s: 100%;
  --theme-primary-l: 36.9%;
  --theme-primary-light: hsl(156, 100%, 40.9%);
  --theme-primary-light-h: 156;
  --theme-primary-light-s: 100%;
  --theme-primary-light-l: 40.9%;
  --theme-primary-lighter: hsl(156, 100%, 95%);
  --theme-primary-lighter-h: 156;
  --theme-primary-lighter-s: 100%;
  --theme-primary-lighter-l: 95%;
  --theme-primary-dark: hsl(156, 100%, 32.9%);
  --theme-primary-dark-h: 156;
  --theme-primary-dark-s: 100%;
  --theme-primary-dark-l: 32.9%;
  --theme-primary-darker: hsl(156, 100%, 20%);
  --theme-primary-darker-h: 156;
  --theme-primary-darker-s: 100%;
  --theme-primary-darker-l: 20%;
  --theme-success: hsl(156, 100%, 36.9%);
  --theme-success-h: 156;
  --theme-success-s: 100%;
  --theme-success-l: 36.9%;
  --theme-success-light: hsl(156, 100%, 40.9%);
  --theme-success-light-h: 156;
  --theme-success-light-s: 100%;
  --theme-success-light-l: 40.9%;
  --theme-success-lighter: hsl(156, 100%, 95%);
  --theme-success-lighter-h: 156;
  --theme-success-lighter-s: 100%;
  --theme-success-lighter-l: 95%;
  --theme-success-dark: hsl(156, 100%, 32.9%);
  --theme-success-dark-h: 156;
  --theme-success-dark-s: 100%;
  --theme-success-dark-l: 32.9%;
  --theme-success-darker: hsl(156, 100%, 20%);
  --theme-success-darker-h: 156;
  --theme-success-darker-s: 100%;
  --theme-success-darker-l: 20%;
  --theme-warning: hsl(31, 83.1%, 55.9%);
  --theme-warning-h: 31;
  --theme-warning-s: 83.1%;
  --theme-warning-l: 55.9%;
  --theme-warning-light: hsl(31, 83.1%, 59.9%);
  --theme-warning-light-h: 31;
  --theme-warning-light-s: 83.1%;
  --theme-warning-light-l: 59.9%;
  --theme-warning-lighter: hsl(31, 83.1%, 95%);
  --theme-warning-lighter-h: 31;
  --theme-warning-lighter-s: 83.1%;
  --theme-warning-lighter-l: 95%;
  --theme-warning-dark: hsl(31, 83.1%, 51.9%);
  --theme-warning-dark-h: 31;
  --theme-warning-dark-s: 83.1%;
  --theme-warning-dark-l: 51.9%;
  --theme-warning-darker: hsl(31, 83.1%, 20%);
  --theme-warning-darker-h: 31;
  --theme-warning-darker-s: 83.1%;
  --theme-warning-darker-l: 20%;
  --theme-danger: hsl(0, 100%, 65.7%);
  --theme-danger-h: 0;
  --theme-danger-s: 100%;
  --theme-danger-l: 65.7%;
  --theme-danger-light: hsl(0, 100%, 69.7%);
  --theme-danger-light-h: 0;
  --theme-danger-light-s: 100%;
  --theme-danger-light-l: 69.7%;
  --theme-danger-lighter: hsl(0, 100%, 95%);
  --theme-danger-lighter-h: 0;
  --theme-danger-lighter-s: 100%;
  --theme-danger-lighter-l: 95%;
  --theme-danger-dark: hsl(0, 100%, 61.7%);
  --theme-danger-dark-h: 0;
  --theme-danger-dark-s: 100%;
  --theme-danger-dark-l: 61.7%;
  --theme-danger-darker: hsl(0, 100%, 20%);
  --theme-danger-darker-h: 0;
  --theme-danger-darker-s: 100%;
  --theme-danger-darker-l: 20%;
  --color-text: #343434;
  --color-text-inverse: #fff;
  --color-text-placeholder: #a9a9a9;
  --color-text-disabled: #bcbcbc;
  --color-text-caption: #909090;
  --color-link: var(--theme-primary);
  --color-shadow: rgba(56, 56, 56, 0.15);
  --background-active: #e6e6e6;
  --background-disabled: #f2f2f2;
  --border-color: #dcdcdc;
  --border-disabled: #e6e6e6;
  --radius-xs: 2px;
  --radius-sm: 2px;
  --radius-md: 4px;
  --radius-lg: 8px;
  --radius-xl: 8px;
  --radius-round: 1000px;
  --radius-circle: 50%;
  --padding-h-xs: 8px;
  --padding-h-sm: 12px;
  --padding-h-md: 16px;
  --padding-h-lg: 20px;
  --padding-h-xl: 24px;
  --padding-v-xs: 6px;
  --padding-v-sm: 8px;
  --padding-v-md: 12px;
  --padding-v-lg: 16px;
  --padding-v-xl: 18px;
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --zindex-mask: 1000;
  --zindex-popup: 1100;
  --zindex-tooltip: 1700;
  --opacity-disabled: 0.5;
  --opacity-mask: 0.5;
  --opacity-toast: 0.8;
  --opacity-tooltip: 0.8;
  --box-shadow-light: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-dark: 0 4px 12px rgba(0, 0, 0, 0.2);
  --action-sheet-background: #fff;
  --action-sheet-active-background: var(--background-active);
  --action-sheet-margin: 0;
  --action-sheet-item-height: 50px;
  --action-sheet-item-font-size: 16px;
  --activity-indicator-size-md: 20px;
  --activity-indicator-size-lg: 32px;
  --activity-indicator-path-color: #e6e6e6;
  --activity-indicator-spinner-color: #989697;
  --alert-button-height: 50px;
  --alert-button-font-size: 18px;
  --alert-button-background: var(--button-default-background);
  --alert-button-active-background: var(--background-active);
  --badge-height: 14px;
  --badge-font-size: var(--font-size-xs);
  --badge-padding-h: 4px;
  --badge-dot-diameter: 8px;
  --badge-border-radius-rect: var(--radius-sm);
  --badge-border-radius-round: var(--radius-round);
  --badge-sup-top: -4px;
  --badge-text-color: #fff;
  --button-height-xs: 28px;
  --button-height-sm: 36px;
  --button-height-md: 44px;
  --button-height-lg: 52px;
  --button-padding-h-xs: var(--padding-h-xs);
  --button-padding-h-sm: var(--padding-h-sm);
  --button-padding-h-md: var(--padding-h-md);
  --button-padding-h-lg: var(--padding-h-lg);
  --button-font-size-xs: 12px;
  --button-font-size-sm: 14px;
  --button-font-size-md: 16px;
  --button-font-size-lg: 18px;
  --button-icon-size-xs: 16px;
  --button-icon-size-sm: 18px;
  --button-icon-size-md: 22px;
  --button-icon-size-lg: 26px;
  --button-disabled-opacity: var(--opacity-disabled);
  --button-disabled-color-ghost: var(--border-disabled);
  --button-default-background: var(--color-text-inverse);
  --button-default-border: var(--theme-default);
  --button-default-color: var(--color-text);
  --button-default-active-background: var(--background-active);
  --button-default-active-border: var(--background-active);
  --button-default-active-color: var(--color-text);
  --button-default-ghost-border: var(--color-text-inverse);
  --button-default-ghost-color: var(--color-text-inverse);
  --button-default-ghost-active-border: var(--background-active);
  --button-default-ghost-active-color: var(--background-active);
  --button-default-shadow-color: rgba(230, 230, 230, 0.3);
  --button-primary-background: var(--theme-primary);
  --button-primary-border: var(--theme-primary);
  --button-primary-color: var(--color-text-inverse);
  --button-primary-active-background: var(--theme-primary-dark);
  --button-primary-active-border: var(--theme-primary-dark);
  --button-primary-active-color: rgba(255, 255, 255, 0.4);
  --button-primary-ghost-border: var(--theme-primary);
  --button-primary-ghost-color: var(--theme-primary);
  --button-primary-ghost-active-border: var(--theme-primary-dark);
  --button-primary-ghost-active-color: var(--theme-primary-dark);
  --button-primary-shadow-color: rgba(0, 188, 112, 0.3);
  --button-danger-background: var(--theme-danger);
  --button-danger-border: var(--theme-danger);
  --button-danger-color: var(--color-text-inverse);
  --button-danger-active-background: var(--theme-danger-dark);
  --button-danger-active-border: var(--theme-danger-dark);
  --button-danger-active-color: rgba(255, 255, 255, 0.4);
  --button-danger-ghost-border: var(--theme-danger);
  --button-danger-ghost-color: var(--theme-danger);
  --button-danger-ghost-active-border: var(--theme-danger-dark);
  --button-danger-ghost-active-color: var(--theme-danger-dark);
  --button-danger-shadow-color: rgba(255, 80, 80, 0.3);
  --calendar-height: 45px;
  --calendar-background: #fff;
  --calendar-week-bar-fontsize: 14px;
  --calendar-week-bar-background: #f2f2f2;
  --calendar-week-bar-color: var(--color-text-caption);
  --calendar-section-padding: 15px;
  --calendar-day-fontsize: 16px;
  --calendar-day-title-fontsize: 17px;
  --calendar-day-color: var(--color-text);
  --calendar-day-today-background: var(--theme-primary-lighter);
  --calendar-day-today-color: var(--theme-primary);
  --calendar-day-selected-background: var(--theme-primary);
  --calendar-day-selected-color: #fff;
  --calendar-day-selected-shadow: rgba(0, 188, 112, 0.3);
  --calendar-day-range-background: var(--theme-primary-lighter);
  --calendar-day-range-color: var(--theme-primary);
  --calendar-day-height: 40px;
  --carousel-pagination-distance: 8px;
  --carousel-pagination-rect-width: 19px;
  --carousel-pagination-rect-height: 4px;
  --carousel-pagination-rect-border-radius: 1px;
  --carousel-pagination-rect-step: 2px;
  --carousel-pagination-rect-color: rgba(255, 255, 255, 0.6);
  --carousel-pagination-rect-color-active: #fff;
  --cell-height: 52px;
  --cell-background: #fff;
  --cell-background-active: var(--background-active);
  --cell-line-padding-left: 16px;
  --cell-padding-h: 16px;
  --cell-padding-v: 15px;
  --cell-title-color: var(--color-text);
  --cell-title-font-size: 15px;
  --cell-title-line-height: 22px;
  --cell-description-color: var(--color-text-caption);
  --cell-description-font-size: 15px;
  --cell-label-width: 100px;
  --cell-arrow-color: #bcbcbc;
  --cell-arrow-length: 10px;
  --cell-arrow-border-width: 2px;
  --checkbox-size: 18px;
  --checkbox-radius: var(--radius-md);
  --checkbox-background: var(--color-text-inverse);
  --checkbox-border-color: var(--border-color);
  --checkbox-active-border-color: var(--theme-primary);
  --checkbox-disabled-background: var(--background-disabled);
  --checkbox-disabled-color: #bcbcbc;
  --checkbox-arrow-color: var(--color-text-inverse);
  --collapse-height: 52px;
  --collapse-padding-h: 16px;
  --collapse-padding-v: 15px;
  --collapse-arrow-color: #bcbcbc;
  --collapse-arrow-disabled-color: #eee;
  --confirm-button-height: 50px;
  --confirm-button-font-size: 18px;
  --confirm-button-background: var(--button-default-background);
  --confirm-button-active-background: var(--background-active);
  --input-clear-icon-width: 16px;
  --input-clear-icon-color: #bcbcbc;
  --input-padding-v: var(--cell-padding-v);
  --input-cursor-color: #597cf6;
  --input-cursor-width: 2px;
  --input-cursor-height: calc(var(--cell-title-line-height) - 4px);
  --input-length-count-color: var(--color-text-disabled);
  --input-height: 28px;
  --input-line-height: var(--cell-title-line-height);
  --image-preview-origin-button-background: #2d2d2d;
  --keyboard-background: #fff;
  --keyboard-item-background: #fff;
  --keyboard-item-height: 52px;
  --keyboard-item-font-size: 30px;
  --loading-background: rgba(0, 0, 0, var(--opacity-toast));
  --message-min-height-md: 29px;
  --message-min-height-lg: 40px;
  --message-font-size-md: 12px;
  --message-font-size-lg: 14px;
  --message-icon-size-md: 14px;
  --message-icon-size-lg: 16px;
  --message-padding-v-md: 6px;
  --message-padding-h-md: 8px;
  --message-padding-v-lg: 8px;
  --message-padding-h-lg: 12px;
  --modal-background: #fff;
  --modal-title-font-size: 18px;
  --modal-title-color: var(--color-text);
  --modal-close-color: #ccc;
  --modal-close-active-color: #999;
  --nav-bar-color: #fdfdfd;
  --nav-bar-height: 44px;
  --nav-bar-icon-size: 24px;
  --notice-bar-height: 24px;
  --panel-header-color: var(--color-text-caption);
  --panel-header-font-size: 14px;
  --panel-body-color: var(--color-text);
  --panel-body-font-size: 15px;
  --panel-body-background: #fff;
  --picker-background: #fff;
  --picker-mask-background-start: rgba(255, 255, 255, 0.4);
  --picker-mask-background-end: rgba(255, 255, 255, 0.8);
  --picker-header-height: 45px;
  --picker-header-font-size: 16px;
  --picker-header-background: #f7f7f7;
  --progress-background: #f2f2f2;
  --progress-width-lg: 100%;
  --progress-width-md: 100%;
  --progress-width-sm: 140px;
  --progress-size-lg: 200px;
  --progress-size-md: 150px;
  --progress-size-sm: 80px;
  --progress-font-size-circle-lg: 30px;
  --progress-font-size-circle-md: 24px;
  --progress-font-size-circle-sm: 18px;
  --pull-control-height: 50px;
  --pull-control-font-size: 14px;
  --radio-size: 18px;
  --radio-radius: var(--radius-circle);
  --radio-background: var(--color-text-inverse);
  --radio-border-color: var(--border-color);
  --radio-active-border-color: var(--theme-primary);
  --radio-disabled-background: var(--background-disabled);
  --radio-disabled-color: #bcbcbc;
  --radio-arrow-color: var(--color-text-inverse);
  --search-bar-font-size: 14px;
  --search-bar-icon-size: 18px;
  --search-bar-background: #fff;
  --search-bar-inner-background: #f2f2f2;
  --slider-line-background: var(--border-color);
  --slider-line-height: 2px;
  --slider-line-dot-width: 6px;
  --slider-line-dot-color: #fff;
  --slider-vertical-line-width: 2px;
  --slider-handle-size: 24px;
  --slider-handle-size-small: 16px;
  --slider-mark-handle: 24px;
  --slider-mark-size: 15px;
  --slider-handle-background: #fff;
  --slider-handle-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  --stepper-input-background: #fff;
  --stepper-height-md: 28px;
  --stepper-height-lg: 36px;
  --stepper-icon-font-size-md: 12px;
  --stepper-icon-font-size-lg: 14px;
  --stepper-input-width-md: 50px;
  --stepper-input-width-lg: 60px;
  --stepper-input-disabled: var(--color-text-disabled);
  --stepper-input-font-size-md: 14px;
  --stepper-input-font-size-lg: 16px;
  --stepper-input-margin-h-md: 4px;
  --stepper-input-margin-h-lg: 4px;
  --stepper-input-padding-h-md: 8px;
  --stepper-input-padding-h-lg: 8px;
  --switch-width: 53px;
  --switch-height: 32px;
  --switch-handle-size: 26px;
  --switch-border-color: var(--border-color);
  --switch-background: var(--border-color);
  --switch-handle-background: #fff;
  --tabs-height: 45px;
  --tabs-item-font-size: var(--font-size-lg);
  --tabs-line-height: 2px;
  --tabs-item-horizontal-padding: 20px;
  --tabs-item-vertical-padding: 12px;
  --toast-border-radius: 6px;
  --toast-background: rgba(0, 0, 0, var(--opacity-toast));
  --toast-color: var(--color-text-inverse);
  --toast-font-size: 13px;
  --tooltip-font-size: 12px;
  --tooltip-color: var(--color-text-inverse);
  --tooltip-arrow-size: 4px;
  --tooltip-padding-v: var(--padding-v-xs);
  --tooltip-padding-h: var(--padding-h-xs);
  --tooltip-background: rgba(0, 0, 0, var(--opacity-tooltip));
  --tabbar-height: 50px;
  --tabbar-hidden-bottom: -50px;
  --tabbar-background: #fff;
  --tabbar-item-font-size: 12px;
  --tabbar-item-active-color: var(--theme-primary);
  --wheel-item-height: 34px;
  --wheel-item-rows: 5;
  --wheel-item-font-size: 18px;
  --wheel-color: var(--color-text);
  --icon-font-size-sm: 16px;
  --icon-font-size-md: 24px;
  --icon-font-size-lg: 32px;
  --icon-theme-default: var(--color-text);
  --stack-picker-background: #fff;
  --stack-picker-shadow: 0 0 10px rgba(0, 0, 0, .3);
  --stack-picker-error: #ff5050;
  --stack-picker-stack-item-font-size: 16px;
  --stack-picker-crumbs-line-height: 24px;
  --stack-picker-stack-item-height: 35px;
  --stack-picker-padding: 20px;
  --stack-picker-left: 60px;
}

@-webkit-keyframes za-fade-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes za-fade-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.za-fade-enter {
  -webkit-animation: za-fade-enter both ease-in;
          animation: za-fade-enter both ease-in;
}

@-webkit-keyframes za-fade-leave {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes za-fade-leave {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.za-fade-leave {
  -webkit-animation: za-fade-leave both ease-out;
          animation: za-fade-leave both ease-out;
}

@-webkit-keyframes za-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 0.1);
            transform: scale3d(1, 1, 0.1);
  }
}

@keyframes za-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 0.1);
            transform: scale3d(1, 1, 0.1);
  }
}
.za-door-enter {
  -webkit-animation: za-door-enter both cubic-bezier(0.4, 0, 0, 0);
          animation: za-door-enter both cubic-bezier(0.4, 0, 0, 0);
}

@-webkit-keyframes za-door-leave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
            transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
            transform: scale3d(0, 1, 0.1);
  }
}

@keyframes za-door-leave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
            transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
            transform: scale3d(0, 1, 0.1);
  }
}
.za-door-leave {
  -webkit-animation: za-door-leave both;
          animation: za-door-leave both;
}

@-webkit-keyframes za-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@keyframes za-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
.za-flip-enter {
  -webkit-animation: za-flip-enter both ease-in;
          animation: za-flip-enter both ease-in;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}

@-webkit-keyframes za-flip-leave {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

@keyframes za-flip-leave {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}
.za-flip-leave {
  -webkit-animation: za-flip-leave both;
          animation: za-flip-leave both;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}

@-webkit-keyframes za-rotate-enter {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
            transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes za-rotate-enter {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
            transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.za-rotate-enter {
  -webkit-animation: za-rotate-enter both;
          animation: za-rotate-enter both;
}

@-webkit-keyframes za-rotate-leave {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
            transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes za-rotate-leave {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
            transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}
.za-rotate-leave {
  -webkit-animation: za-rotate-leave both;
          animation: za-rotate-leave both;
}

@-webkit-keyframes za-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-slideUp-enter {
  -webkit-animation: za-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
          animation: za-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes za-slideUp-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
}

@keyframes za-slideUp-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
}
.za-slideUp-leave {
  -webkit-animation: za-slideUp-leave both;
          animation: za-slideUp-leave both;
}

@-webkit-keyframes za-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-slideDown-enter {
  -webkit-animation: za-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
          animation: za-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes za-slideDown-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
}

@keyframes za-slideDown-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
}
.za-slideDown-leave {
  -webkit-animation: za-slideDown-leave both;
          animation: za-slideDown-leave both;
}

@-webkit-keyframes za-slideLeft-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
            transform: translate3d(150px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-slideLeft-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
            transform: translate3d(150px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-slideLeft-enter {
  -webkit-animation: za-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
          animation: za-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes za-slideLeft-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(150px, 0, 0);
            transform: translate3d(150px, 0, 0);
  }
}

@keyframes za-slideLeft-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(150px, 0, 0);
            transform: translate3d(150px, 0, 0);
  }
}
.za-slideLeft-leave {
  -webkit-animation: za-slideLeft-leave both;
          animation: za-slideLeft-leave both;
}

@-webkit-keyframes za-slideRight-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
            transform: translate3d(-150px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-slideRight-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
            transform: translate3d(-150px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-slideRight-enter {
  -webkit-animation: za-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
          animation: za-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes za-slideRight-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
            transform: translate3d(-150px, 0, 0);
  }
}

@keyframes za-slideRight-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
            transform: translate3d(-150px, 0, 0);
  }
}
.za-slideRight-leave {
  -webkit-animation: za-slideRight-leave both;
          animation: za-slideRight-leave both;
}

@-webkit-keyframes za-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes za-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.za-zoom-enter {
  -webkit-animation: za-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
          animation: za-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes za-zoom-leave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes za-zoom-leave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
.za-zoom-leave {
  -webkit-animation: za-zoom-leave both;
          animation: za-zoom-leave both;
}

@-webkit-keyframes za-zoom-fade-enter {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes za-zoom-fade-enter {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes za-zoom-fade-leave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes za-zoom-fade-leave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
.za-zoomFade-enter {
  -webkit-animation: za-zoom-fade-enter both cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: za-zoom-fade-enter both cubic-bezier(0.08, 0.82, 0.17, 1);
}

.za-zoomFade-leave {
  -webkit-animation: za-zoom-fade-leave both cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: za-zoom-fade-leave both cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes za-moveUp-enter {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-moveUp-enter {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-moveUp-enter {
  -webkit-animation: za-moveUp-enter both ease-in;
          animation: za-moveUp-enter both ease-in;
}

@-webkit-keyframes za-moveUp-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@keyframes za-moveUp-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}
.za-moveUp-leave {
  -webkit-animation: za-moveUp-leave both ease-out;
          animation: za-moveUp-leave both ease-out;
}

@-webkit-keyframes za-moveDown-enter {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-moveDown-enter {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-moveDown-enter {
  -webkit-animation: za-moveDown-enter both ease-in;
          animation: za-moveDown-enter both ease-in;
}

@-webkit-keyframes za-moveDown-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@keyframes za-moveDown-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}
.za-moveDown-leave {
  -webkit-animation: za-moveDown-leave both ease-out;
          animation: za-moveDown-leave both ease-out;
}

@-webkit-keyframes za-moveLeft-enter {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-moveLeft-enter {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-moveLeft-enter {
  -webkit-animation: za-moveLeft-enter both ease-in;
          animation: za-moveLeft-enter both ease-in;
}

@-webkit-keyframes za-moveLeft-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

@keyframes za-moveLeft-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}
.za-moveLeft-leave {
  -webkit-animation: za-moveLeft-leave both ease-out;
          animation: za-moveLeft-leave both ease-out;
}

@-webkit-keyframes za-moveRight-enter {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes za-moveRight-enter {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.za-moveRight-enter {
  -webkit-animation: za-moveRight-enter both ease-in;
          animation: za-moveRight-enter both ease-in;
}

@-webkit-keyframes za-moveRight-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

@keyframes za-moveRight-leave {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}
.za-moveRight-leave {
  -webkit-animation: za-moveRight-leave both ease-out;
          animation: za-moveRight-leave both ease-out;
}

@-webkit-keyframes za-scaleDown-enter {
  from {
    opacity: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes za-scaleDown-enter {
  from {
    opacity: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.za-scaleDown-enter {
  -webkit-animation: za-scaleDown-enter both;
          animation: za-scaleDown-enter both;
}

@-webkit-keyframes za-scaleDown-leave {
  from {
    opacity: 1;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
}

@keyframes za-scaleDown-leave {
  from {
    opacity: 1;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
}
.za-scaleDown-leave {
  -webkit-animation: za-scaleDown-leave both;
          animation: za-scaleDown-leave both;
}

@-webkit-keyframes za-menu-slide-up-enter {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes za-menu-slide-up-enter {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes za-menu-slide-up-leave {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes za-menu-slide-up-leave {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes za-menu-slide-down-enter {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes za-menu-slide-down-enter {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes za-menu-slide-down-leave {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes za-menu-slide-down-leave {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
.za-menuSlide-up-enter {
  -webkit-animation: za-menu-slide-up-enter both cubic-bezier(0.23, 1, 0.32, 1);
          animation: za-menu-slide-up-enter both cubic-bezier(0.23, 1, 0.32, 1);
}

.za-menuSlide-up-leave {
  -webkit-animation: za-menu-slide-up-leave both cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation: za-menu-slide-up-leave both cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.za-menuSlide-down-enter {
  -webkit-animation: za-menu-slide-down-enter both cubic-bezier(0.23, 1, 0.32, 1);
          animation: za-menu-slide-down-enter both cubic-bezier(0.23, 1, 0.32, 1);
}

.za-menuSlide-down-leave {
  -webkit-animation: za-menu-slide-down-leave both cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation: za-menu-slide-down-leave both cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*[contenteditable] {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

*:focus {
  outline: none;
}

a {
  background: transparent;
  text-decoration: none;
  outline: none;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-action-sheet {
  margin: 0;
  margin: var(--action-sheet-margin);
}
.za-action-sheet--spacing {
  margin: 0 10px 10px;
}
.za-action-sheet--spacing .za-action-sheet__actions, .za-action-sheet--spacing .za-action-sheet__cancel {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-action-sheet__actions, .za-action-sheet__cancel {
  overflow: hidden;
  background-color: #fff;
  background-color: var(--action-sheet-background);
}

.za-action-sheet__cancel {
  margin-top: 10px;
}

.za-action-sheet__item {
  display: block;
  position: relative;
  height: 50px;
  height: var(--action-sheet-item-height);
  line-height: 50px;
  line-height: var(--action-sheet-item-height);
  font-size: 16px;
  font-size: var(--action-sheet-item-font-size);
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
}
.za-action-sheet__item, .za-action-sheet__item:active, .za-action-sheet__item:hover, .za-action-sheet__item:focus, .za-action-sheet__item:visited {
  text-decoration: none;
}
.za-action-sheet__item--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-action-sheet__item--block {
  display: block;
  width: 100%;
}

.za-action-sheet__item:active {
  background-color: #e6e6e6;
  background-color: var(--action-sheet-active-background);
}
.za-action-sheet__item:not(:first-of-type):after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-action-sheet__item:not(:first-of-type):after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-action-sheet__item--primary {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-action-sheet__item--danger {
  color: hsl(0, 100%, 65.7%);
  color: var(--theme-danger);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-activity-indicator {
  display: inline-block;
  position: relative;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: 20px;
  width: var(--activity-indicator-size-md);
  height: 20px;
  height: var(--activity-indicator-size-md);
  /* sizes */
}
.za-activity-indicator__path {
  stroke: #e6e6e6;
  stroke: var(--activity-indicator-path-color);
}

.za-activity-indicator__line {
  stroke-dasharray: 314.1592674;
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-primary);
}

.za-activity-indicator--lg {
  width: 32px;
  width: var(--activity-indicator-size-lg);
  height: 32px;
  height: var(--activity-indicator-size-lg);
}

.za-activity-indicator--circular {
  display: inline-block;
}
.za-activity-indicator--circular svg {
  vertical-align: top;
  -webkit-animation: za-activity-indicator_rotate360 2s linear infinite;
          animation: za-activity-indicator_rotate360 2s linear infinite;
}
.za-activity-indicator--circular circle {
  stroke-linecap: round;
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-primary);
  -webkit-animation: za-activity-indicator_rotate-circular 1.5s ease-in-out infinite;
          animation: za-activity-indicator_rotate-circular 1.5s ease-in-out infinite;
}

.za-activity-indicator--spinner {
  -webkit-animation: za-activity-indicator_rotate360 0.8s linear infinite;
          animation: za-activity-indicator_rotate360 0.8s linear infinite;
  -webkit-animation-timing-function: steps(12);
          animation-timing-function: steps(12);
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
  opacity: 1;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
  opacity: 0.9375;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  opacity: 0.875;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(4) {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
  opacity: 0.8125;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(5) {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
  opacity: 0.75;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(6) {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  opacity: 0.6875;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(7) {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
  opacity: 0.625;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(8) {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
  opacity: 0.5625;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(9) {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  opacity: 0.5;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(10) {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
  opacity: 0.4375;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(11) {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
  opacity: 0.375;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}
.za-activity-indicator--spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.za-activity-indicator--spinner div:nth-of-type(12) {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
  opacity: 0.3125;
}
.za-activity-indicator--spinner div::before {
  content: "";
  display: block;
  width: 2px;
  height: 25%;
  margin: 0 auto;
  background-color: #989697;
  background-color: var(--activity-indicator-spinner-color);
  border-radius: 40%;
}

@-webkit-keyframes za-activity-indicator_rotate-circular {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: -60;
  }
  100% {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: -180;
  }
}

@keyframes za-activity-indicator_rotate-circular {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: -60;
  }
  100% {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: -180;
  }
}
@-webkit-keyframes za-activity-indicator_rotate360 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes za-activity-indicator_rotate360 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-alert .za-modal__body {
  text-align: center;
}

.za-alert .za-modal__footer {
  padding: 0;
}

.za-alert .za-popup {
  position: relative;
  background-color: #fff;
  background-color: var(--modal-background);
  -webkit-box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
          box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
  -webkit-box-shadow: 0 7px 21px var(--color-shadow);
          box-shadow: 0 7px 21px var(--color-shadow);
  overflow: hidden;
}
.za-alert__button {
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
  padding: 0 20px;
  padding: 0 var(--button-padding-h-lg);
  font-size: 18px;
  font-size: var(--button-font-size-lg);
  height: 52px;
  height: var(--button-height-lg);
  line-height: 1.5;
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
  background-color: #fff;
  background-color: var(--alert-button-background);
}
.za-alert__button, .za-alert__button:active, .za-alert__button:hover, .za-alert__button:focus, .za-alert__button:visited {
  text-decoration: none;
}
.za-alert__button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-alert__button--block {
  display: block;
  width: 100%;
}

.za-alert__button--circle {
  width: 52px;
  width: var(--button-height-lg);
  padding: 0;
}

.za-alert__button .za-icon {
  font-size: 26px;
  font-size: var(--button-icon-size-lg);
}
.za-alert__button--link {
  line-height: 52px;
  line-height: var(--button-height-lg);
}

.za-alert__button * + span {
  margin-left: calc(20px / 2);
  margin-left: calc(var(--button-padding-h-lg) / 2);
}
.za-alert__button:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-alert__button:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-alert__button:active {
  background-color: #e6e6e6;
  background-color: var(--alert-button-active-background);
}

.za-alert--radius .za-popup {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-badge {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.za-badge__content {
  background-color: hsl(0, 100%, 65.7%);
  background-color: var(--theme-danger);
}

.za-badge__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  top: auto;
  color: #fff;
  color: var(--badge-text-color);
  font-size: 10px;
  font-size: var(--badge-font-size);
  height: 14px;
  height: var(--badge-height);
  padding: 0 4px;
  padding: 0 var(--badge-padding-h);
  white-space: nowrap;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.za-badge--primary .za-badge__content {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}

.za-badge--success .za-badge__content {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-success);
}

.za-badge--warning .za-badge__content {
  background-color: hsl(31, 83.1%, 55.9%);
  background-color: var(--theme-warning);
}

.za-badge--danger .za-badge__content {
  background-color: hsl(0, 100%, 65.7%);
  background-color: var(--theme-danger);
}

.za-badge--dot .za-badge__content {
  width: 8px;
  width: var(--badge-dot-diameter);
  height: 8px;
  height: var(--badge-dot-diameter);
  padding: 0;
  border-radius: 50%;
  border-radius: var(--radius-circle);
}

.za-badge--radius .za-badge__content {
  border-radius: 2px;
  border-radius: var(--badge-border-radius-rect);
}

.za-badge--round .za-badge__content {
  min-width: 14px;
  min-width: var(--badge-height);
  border-radius: 1000px;
  border-radius: var(--badge-border-radius-round);
}

.za-badge--circle .za-badge__content {
  width: 14px;
  width: var(--badge-height);
  border-radius: 50%;
  border-radius: var(--radius-circle);
  padding: 0;
}

.za-badge--leaf .za-badge__content {
  min-width: 14px;
  min-width: var(--badge-height);
  border-radius: 1000px 1000px 1000px 0;
  border-radius: var(--badge-border-radius-round) var(--badge-border-radius-round) var(--badge-border-radius-round) 0;
}

.za-badge--sup .za-badge__content {
  position: absolute;
  top: 0;
  left: 100%;
  -webkit-transform: translateX(calc(14px / -2)) translateY(-4px);
      -ms-transform: translateX(calc(14px / -2)) translateY(-4px);
          transform: translateX(calc(14px / -2)) translateY(-4px);
  -webkit-transform: translateX(calc(var(--badge-height) / -2)) translateY(var(--badge-sup-top));
      -ms-transform: translateX(calc(var(--badge-height) / -2)) translateY(var(--badge-sup-top));
          transform: translateX(calc(var(--badge-height) / -2)) translateY(var(--badge-sup-top));
}

.za-badge--sup.za-badge--dot .za-badge__content {
  -webkit-transform: translateX(calc(8px / -2)) translateY(-50%);
      -ms-transform: translateX(calc(8px / -2)) translateY(-50%);
          transform: translateX(calc(8px / -2)) translateY(-50%);
  -webkit-transform: translateX(calc(var(--badge-dot-diameter) / -2)) translateY(-50%);
      -ms-transform: translateX(calc(var(--badge-dot-diameter) / -2)) translateY(-50%);
          transform: translateX(calc(var(--badge-dot-diameter) / -2)) translateY(-50%);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-button {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
  border-width: 1PX;
  border-style: solid;
  border-radius: 4px;
  border-radius: var(--radius-md);
  /* Button sizes */
  /* Button themes */
}
.za-button, .za-button:active, .za-button:hover, .za-button:focus, .za-button:visited {
  text-decoration: none;
}
.za-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-button--block {
  display: block;
  width: 100%;
}

.za-button, .za-button:hover {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-button:active {
  background-color: #e6e6e6;
  background-color: var(--button-default-active-background);
  border-color: #e6e6e6;
  border-color: var(--button-default-active-border);
  color: #343434;
  color: var(--button-default-active-color);
}
.za-button--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-button--disabled:active, .za-button--disabled:focus, .za-button--disabled:visited {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}

.za-button--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
          box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
}

.za-button--link, .za-button--link:hover {
  background-color: transparent;
  border-color: transparent;
  color: #343434;
  color: var(--button-default-color);
}
.za-button--link:active {
  color: #343434;
  color: var(--button-default-active-color);
}
.za-button--link.za-button--disabled, .za-button--link.za-button--disabled:active, .za-button--link.za-button--disabled:hover, .za-button--link.za-button--disabled:focus, .za-button--link.za-button--disabled:visited {
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
  pointer-events: none;
}

.za-button--ghost, .za-button--ghost:hover {
  background-color: transparent;
  border-color: #fff;
  border-color: var(--button-default-ghost-border);
  color: #fff;
  color: var(--button-default-ghost-color);
}
.za-button--ghost:active {
  background-color: transparent;
  border-color: #e6e6e6;
  border-color: var(--button-default-ghost-active-border);
  color: #e6e6e6;
  color: var(--button-default-ghost-active-color);
}
.za-button--ghost.za-button--disabled, .za-button--ghost.za-button--disabled:active, .za-button--ghost.za-button--disabled:hover, .za-button--ghost.za-button--disabled:focus, .za-button--ghost.za-button--disabled:visited {
  background-color: transparent;
  border-color: #e6e6e6;
  border-color: var(--border-disabled);
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
}

.za-button--lg {
  padding: 0 20px;
  padding: 0 var(--button-padding-h-lg);
  font-size: 18px;
  font-size: var(--button-font-size-lg);
  height: 52px;
  height: var(--button-height-lg);
  line-height: 1.5;
}
.za-button--lg.za-button--circle {
  width: 52px;
  width: var(--button-height-lg);
  padding: 0;
}

.za-button--lg .za-icon {
  font-size: 26px;
  font-size: var(--button-icon-size-lg);
}
.za-button--lg.za-button--link {
  line-height: 52px;
  line-height: var(--button-height-lg);
}

.za-button--lg * + span {
  margin-left: calc(20px / 2);
  margin-left: calc(var(--button-padding-h-lg) / 2);
}

.za-button--md {
  padding: 0 16px;
  padding: 0 var(--button-padding-h-md);
  font-size: 16px;
  font-size: var(--button-font-size-md);
  height: 44px;
  height: var(--button-height-md);
  line-height: 1.5;
}
.za-button--md.za-button--circle {
  width: 44px;
  width: var(--button-height-md);
  padding: 0;
}

.za-button--md .za-icon {
  font-size: 22px;
  font-size: var(--button-icon-size-md);
}
.za-button--md.za-button--link {
  line-height: 44px;
  line-height: var(--button-height-md);
}

.za-button--md * + span {
  margin-left: calc(16px / 2);
  margin-left: calc(var(--button-padding-h-md) / 2);
}

.za-button--sm {
  padding: 0 12px;
  padding: 0 var(--button-padding-h-sm);
  font-size: 14px;
  font-size: var(--button-font-size-sm);
  height: 36px;
  height: var(--button-height-sm);
  line-height: 1.5;
}
.za-button--sm.za-button--circle {
  width: 36px;
  width: var(--button-height-sm);
  padding: 0;
}

.za-button--sm .za-icon {
  font-size: 18px;
  font-size: var(--button-icon-size-sm);
}
.za-button--sm.za-button--link {
  line-height: 36px;
  line-height: var(--button-height-sm);
}

.za-button--sm * + span {
  margin-left: calc(12px / 2);
  margin-left: calc(var(--button-padding-h-sm) / 2);
}

.za-button--xs {
  padding: 0 8px;
  padding: 0 var(--button-padding-h-xs);
  font-size: 12px;
  font-size: var(--button-font-size-xs);
  height: 28px;
  height: var(--button-height-xs);
  line-height: 1.5;
}
.za-button--xs.za-button--circle {
  width: 28px;
  width: var(--button-height-xs);
  padding: 0;
}

.za-button--xs .za-icon {
  font-size: 16px;
  font-size: var(--button-icon-size-xs);
}
.za-button--xs.za-button--link {
  line-height: 28px;
  line-height: var(--button-height-xs);
}

.za-button--xs * + span {
  margin-left: calc(8px / 2);
  margin-left: calc(var(--button-padding-h-xs) / 2);
}

.za-button--primary, .za-button--primary:hover {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}
.za-button--primary:active {
  background-color: hsl(156, 100%, 32.9%);
  background-color: var(--button-primary-active-background);
  border-color: hsl(156, 100%, 32.9%);
  border-color: var(--button-primary-active-border);
  color: rgba(255, 255, 255, 0.4);
  color: var(--button-primary-active-color);
}
.za-button--primary.za-button--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-button--primary.za-button--disabled:active, .za-button--primary.za-button--disabled:focus, .za-button--primary.za-button--disabled:visited {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}

.za-button--primary.za-button--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
          box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
}

.za-button--primary.za-button--link, .za-button--primary.za-button--link:hover {
  background-color: transparent;
  border-color: transparent;
  color: hsl(156, 100%, 36.9%);
  color: var(--button-primary-ghost-color);
}
.za-button--primary.za-button--link:active {
  color: hsl(156, 100%, 32.9%);
  color: var(--button-primary-ghost-active-color);
}
.za-button--primary.za-button--link.za-button--disabled, .za-button--primary.za-button--link.za-button--disabled:active, .za-button--primary.za-button--link.za-button--disabled:hover, .za-button--primary.za-button--link.za-button--disabled:focus, .za-button--primary.za-button--link.za-button--disabled:visited {
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
  pointer-events: none;
}

.za-button--primary.za-button--ghost, .za-button--primary.za-button--ghost:hover {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-ghost-border);
  color: hsl(156, 100%, 36.9%);
  color: var(--button-primary-ghost-color);
}
.za-button--primary.za-button--ghost:active {
  background-color: transparent;
  border-color: hsl(156, 100%, 32.9%);
  border-color: var(--button-primary-ghost-active-border);
  color: hsl(156, 100%, 32.9%);
  color: var(--button-primary-ghost-active-color);
}
.za-button--primary.za-button--ghost.za-button--disabled, .za-button--primary.za-button--ghost.za-button--disabled:active, .za-button--primary.za-button--ghost.za-button--disabled:hover, .za-button--primary.za-button--ghost.za-button--disabled:focus, .za-button--primary.za-button--ghost.za-button--disabled:visited {
  background-color: transparent;
  border-color: #e6e6e6;
  border-color: var(--border-disabled);
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
}

.za-button--danger, .za-button--danger:hover {
  background-color: hsl(0, 100%, 65.7%);
  background-color: var(--button-danger-background);
  border-color: hsl(0, 100%, 65.7%);
  border-color: var(--button-danger-border);
  color: #fff;
  color: var(--button-danger-color);
}
.za-button--danger:active {
  background-color: hsl(0, 100%, 61.7%);
  background-color: var(--button-danger-active-background);
  border-color: hsl(0, 100%, 61.7%);
  border-color: var(--button-danger-active-border);
  color: rgba(255, 255, 255, 0.4);
  color: var(--button-danger-active-color);
}
.za-button--danger.za-button--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-button--danger.za-button--disabled:active, .za-button--danger.za-button--disabled:focus, .za-button--danger.za-button--disabled:visited {
  background-color: hsl(0, 100%, 65.7%);
  background-color: var(--button-danger-background);
  border-color: hsl(0, 100%, 65.7%);
  border-color: var(--button-danger-border);
  color: #fff;
  color: var(--button-danger-color);
}

.za-button--danger.za-button--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(255, 80, 80, 0.3);
          box-shadow: 0 3px 3px 0 rgba(255, 80, 80, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-danger-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-danger-shadow-color);
}

.za-button--danger.za-button--link, .za-button--danger.za-button--link:hover {
  background-color: transparent;
  border-color: transparent;
  color: hsl(0, 100%, 65.7%);
  color: var(--button-danger-ghost-color);
}
.za-button--danger.za-button--link:active {
  color: hsl(0, 100%, 61.7%);
  color: var(--button-danger-ghost-active-color);
}
.za-button--danger.za-button--link.za-button--disabled, .za-button--danger.za-button--link.za-button--disabled:active, .za-button--danger.za-button--link.za-button--disabled:hover, .za-button--danger.za-button--link.za-button--disabled:focus, .za-button--danger.za-button--link.za-button--disabled:visited {
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
  pointer-events: none;
}

.za-button--danger.za-button--ghost, .za-button--danger.za-button--ghost:hover {
  background-color: transparent;
  border-color: hsl(0, 100%, 65.7%);
  border-color: var(--button-danger-ghost-border);
  color: hsl(0, 100%, 65.7%);
  color: var(--button-danger-ghost-color);
}
.za-button--danger.za-button--ghost:active {
  background-color: transparent;
  border-color: hsl(0, 100%, 61.7%);
  border-color: var(--button-danger-ghost-active-border);
  color: hsl(0, 100%, 61.7%);
  color: var(--button-danger-ghost-active-color);
}
.za-button--danger.za-button--ghost.za-button--disabled, .za-button--danger.za-button--ghost.za-button--disabled:active, .za-button--danger.za-button--ghost.za-button--disabled:hover, .za-button--danger.za-button--ghost.za-button--disabled:focus, .za-button--danger.za-button--ghost.za-button--disabled:visited {
  background-color: transparent;
  border-color: #e6e6e6;
  border-color: var(--border-disabled);
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
}

.za-button--rect {
  border-width: 1PX;
  border-style: solid;
  border-radius: 0;
}

.za-button--radius {
  border-width: 1PX;
  border-style: solid;
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-button--round {
  border-width: 1PX;
  border-style: solid;
  border-radius: 1000px;
  border-radius: var(--radius-round);
}

.za-button--circle {
  border-width: 1PX;
  border-style: solid;
  border-radius: 50%;
  border-radius: var(--radius-circle);
}

.za-button__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-calendar {
  background-color: #fff;
  background-color: var(--calendar-background);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.za-calendar ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
}
.za-calendar li {
  display: inline-block;
  width: 14.28571%;
  text-align: center;
  vertical-align: middle;
}
.za-calendar__bar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  color: #909090;
  color: var(--calendar-week-bar-color);
  background-color: #f2f2f2;
  background-color: var(--calendar-week-bar-background);
  font-size: 14px;
  font-size: var(--calendar-week-bar-fontsize);
  margin: 0;
  padding: 0 15px;
  padding: 0 var(--calendar-section-padding);
  list-style-type: disc;
}
.za-calendar__bar__item {
  height: 40px;
  height: var(--calendar-day-height);
  line-height: 40px;
  line-height: var(--calendar-day-height);
}

.za-calendar__body {
  padding: 10px 0;
  overflow: auto;
}

.za-calendar__month {
  padding: 0 15px;
  padding: 0 var(--calendar-section-padding);
  color: #343434;
  color: var(--calendar-day-color);
}
.za-calendar__month:before {
  content: attr(title);
  display: block;
  margin: 15px auto;
  font-size: 17px;
  font-size: var(--calendar-day-title-fontsize);
  font-weight: 500;
  padding-left: 15px;
}

.za-calendar__day {
  margin: 10px 0;
  position: relative;
  font-size: 16px;
  font-size: var(--calendar-day-fontsize);
  cursor: pointer;
}
.za-calendar__day__content {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}
.za-calendar__day.firstday-1 {
  margin-left: 14.28571%;
}
.za-calendar__day.firstday-2 {
  margin-left: 28.57142%;
}
.za-calendar__day.firstday-3 {
  margin-left: 42.85713%;
}
.za-calendar__day.firstday-4 {
  margin-left: 57.14284%;
}
.za-calendar__day.firstday-5 {
  margin-left: 71.42855%;
}
.za-calendar__day.firstday-6 {
  margin-left: 85.71426%;
}
.za-calendar__day--today .za-calendar__day__content {
  background-color: hsl(156, 100%, 95%);
  background-color: var(--calendar-day-today-background);
  color: hsl(156, 100%, 36.9%);
  color: var(--calendar-day-today-color);
}

.za-calendar__day--selected .za-calendar__day__content {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--calendar-day-selected-background);
  color: #fff;
  color: var(--calendar-day-selected-color);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 188, 112, 0.3);
          box-shadow: 0 2px 5px 0 rgba(0, 188, 112, 0.3);
  -webkit-box-shadow: 0 2px 5px 0 var(--calendar-day-selected-shadow);
          box-shadow: 0 2px 5px 0 var(--calendar-day-selected-shadow);
}

.za-calendar__day--disabled {
  cursor: auto;
}
.za-calendar__day--disabled .za-calendar__day__content {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

.za-calendar__day--range {
  background-color: hsl(156, 100%, 95%);
  background-color: var(--calendar-day-range-background);
  color: hsl(156, 100%, 36.9%);
  color: var(--calendar-day-range-color);
}
.za-calendar__day--range .za-calendar__day__content {
  background-color: hsl(156, 100%, 95%);
  background-color: var(--calendar-day-range-background);
}

.za-calendar__day--range.d6 {
  background-color: transparent;
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}
.za-calendar__day--range.d7 {
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}
.za-calendar__day--range:first-child:not(.d6) {
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}
.za-calendar__day--range:last-child:not(.d7) {
  background-color: transparent;
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}
.za-calendar__day--range:last-child.d7, .za-calendar__day--range:first-child.d6 {
  background-image: none;
}

.za-calendar__day.range-start.range-end {
  background-image: none;
}
.za-calendar__day.range-start:not(.range-end):not(.d6):not(:last-child) {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}
.za-calendar__day.range-end:not(.range-start):not(.d7):not(:first-child) {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, hsl(156, 100%, 95%)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, hsl(156, 100%, 95%) 50%);
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, transparent), color-stop(50%, transparent), color-stop(50%, var(--theme-primary-lighter)));
  background-image: -webkit-linear-gradient(right, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
  background-image: linear-gradient(to left, transparent 0, transparent 50%, var(--theme-primary-lighter) 50%);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-carousel {
  position: relative;
  overflow: hidden;
}
.za-carousel__item {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.za-carousel__pagination {
  position: absolute;
}

.za-carousel__pagination__item {
  background: rgba(255, 255, 255, 0.6);
  background: var(--carousel-pagination-rect-color);
  cursor: pointer;
  border-radius: 1px;
  border-radius: var(--carousel-pagination-rect-border-radius);
}
.za-carousel__pagination__item--active {
  background: #fff;
  background: var(--carousel-pagination-rect-color-active);
}

.za-carousel--horizontal .za-carousel__items {
  will-change: transform;
  white-space: nowrap;
}

.za-carousel--horizontal .za-carousel__pagination {
  left: 50%;
  bottom: 8px;
  bottom: var(--carousel-pagination-distance);
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.za-carousel--horizontal .za-carousel__pagination__item {
  display: inline-block;
  width: 19px;
  width: var(--carousel-pagination-rect-width);
  height: 4px;
  height: var(--carousel-pagination-rect-height);
}
.za-carousel--horizontal .za-carousel__pagination__item:not(:last-child) {
  margin-right: 2px;
  margin-right: var(--carousel-pagination-rect-step);
}

.za-carousel--vertical .za-carousel__pagination {
  top: 50%;
  right: 8px;
  right: var(--carousel-pagination-distance);
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}

.za-carousel--vertical .za-carousel__pagination__item {
  width: 4px;
  width: var(--carousel-pagination-rect-height);
  height: 19px;
  height: var(--carousel-pagination-rect-width);
}
.za-carousel--vertical .za-carousel__pagination__item:not(:last-child) {
  margin-bottom: 2px;
  margin-bottom: var(--carousel-pagination-rect-step);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-cell {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background-color: var(--cell-background);
}
.za-cell__footer {
  color: #909090;
  color: var(--cell-description-color);
}

.za-cell:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-cell:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-cell:after {
  left: 16px;
  left: var(--cell-line-padding-left);
}
.za-cell__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  padding: 0 var(--cell-padding-h);
  font-size: 15px;
  font-size: var(--cell-title-font-size);
  min-height: 52px;
  min-height: var(--cell-height);
}
.za-cell__inner a,
.za-cell__inner a:hover {
  text-decoration: none;
}

.za-cell__body {
  width: 100%;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.za-cell__footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: right;
  font-size: 15px;
  font-size: var(--cell-description-font-size);
}

.za-cell__arrow {
  margin-left: 5px;
  margin-right: 2px;
}
.za-cell__arrow:after {
  display: inline-block;
  content: "";
  border-right: 2px solid #bcbcbc;
  border-right: var(--cell-arrow-border-width) solid var(--cell-arrow-color);
  border-top: 2px solid #bcbcbc;
  border-top: var(--cell-arrow-border-width) solid var(--cell-arrow-color);
  width: 10px;
  width: var(--cell-arrow-length);
  height: 10px;
  height: var(--cell-arrow-length);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.za-cell__icon {
  text-align: center;
  margin-right: 16px;
  margin-right: var(--padding-h-md);
  padding: 15px 0;
  padding: var(--cell-padding-v) 0;
}
.za-cell__icon img {
  max-width: 100%;
  max-height: 100%;
}

.za-cell__title {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 15px 0;
  padding: var(--cell-padding-v) 0;
  overflow: hidden;
  color: #343434;
  color: var(--cell-title-color);
  line-height: 22px;
  line-height: var(--cell-title-line-height);
}
.za-cell__title--label {
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  width: 100px;
  width: var(--cell-label-width);
}

.za-cell__content {
  width: 100%;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.za-cell__content > .za-input {
  padding: 15px 0;
  padding: var(--cell-padding-v) 0;
}

.za-cell__help {
  padding: 0 15px 10px 15px;
}
.za-cell__help:empty {
  display: none;
}

.za-cell--primary .za-cell__footer {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-cell--success .za-cell__footer {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-success);
}

.za-cell--warning .za-cell__footer {
  color: hsl(31, 83.1%, 55.9%);
  color: var(--theme-warning);
}

.za-cell--danger .za-cell__footer {
  color: hsl(0, 100%, 65.7%);
  color: var(--theme-danger);
}

.za-cell--link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.za-cell--link .za-cell__inner:active {
  background-color: #e6e6e6;
  background-color: var(--cell-background-active);
}

.za-cell--disabled .za-cell__body, .za-cell--disabled .za-cell__footer {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-cell--disabled .za-cell__body .za-icon, .za-cell--disabled .za-cell__footer .za-icon {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-checkbox {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #343434;
  color: var(--color-text);
  font-size: 14px;
}
.za-checkbox:not(.za-checkbox--disabled):hover .za-checkbox__inner {
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--checkbox-active-border-color);
}

.za-checkbox__widget {
  display: inline-block;
  vertical-align: middle;
}

.za-checkbox__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.za-checkbox__text {
  vertical-align: middle;
  margin: 0 8px;
}

.za-checkbox__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 18px;
  width: var(--checkbox-size);
  height: 18px;
  height: var(--checkbox-size);
  border: 1PX solid #dcdcdc;
  border: 1PX solid var(--checkbox-border-color);
  border-radius: 4px;
  border-radius: var(--checkbox-radius);
  background: #fff;
  background: var(--checkbox-background);
  -webkit-transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.za-checkbox__inner:before {
  content: "";
  width: calc(18px / 3.5);
  width: calc(var(--checkbox-size) / 3.5);
  height: calc(18px / 2);
  height: calc(var(--checkbox-size) / 2);
  border: calc(18px / 9) solid #fff;
  border: calc(var(--checkbox-size) / 9) solid var(--checkbox-arrow-color);
  border-top: 0;
  border-left: 0;
  margin-top: calc(18px / -12);
  margin-top: calc(var(--checkbox-size) / -12);
  margin-left: calc(18px / 18);
  margin-left: calc(var(--checkbox-size) / 18);
  -webkit-transform: rotate(45deg) scale(0);
      -ms-transform: rotate(45deg) scale(0);
          transform: rotate(45deg) scale(0);
  -webkit-transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.za-checkbox--indeterminate .za-checkbox__inner:before {
  -webkit-transform: rotate(0) scale(1);
      -ms-transform: rotate(0) scale(1);
          transform: rotate(0) scale(1);
  width: calc(18px / 2.33);
  width: calc(var(--checkbox-size) / 2.33);
  height: calc(18px / 2.33);
  height: calc(var(--checkbox-size) / 2.33);
  margin-top: 0;
  margin-left: 0;
  border: none;
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}

.za-checkbox--indeterminate.za-checkbox--disabled .za-checkbox__inner:before {
  -webkit-transform: rotate(0) scale(1);
      -ms-transform: rotate(0) scale(1);
          transform: rotate(0) scale(1);
  background-color: #bcbcbc;
  background-color: var(--checkbox-disabled-color);
}

.za-checkbox--checked .za-checkbox__inner {
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}
.za-checkbox--checked .za-checkbox__inner:before {
  -webkit-transform: rotate(45deg) scale(1);
      -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.za-checkbox--disabled .za-checkbox__input {
  cursor: not-allowed;
}

.za-checkbox--disabled .za-checkbox__text {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

.za-checkbox--disabled .za-checkbox__inner {
  background-color: #f2f2f2;
  background-color: var(--checkbox-disabled-background);
}

.za-checkbox--disabled.za-checkbox--checked .za-checkbox__inner {
  border-color: #dcdcdc;
  border-color: var(--checkbox-border-color);
  background-color: #f2f2f2;
  background-color: var(--checkbox-disabled-background);
}
.za-checkbox--disabled.za-checkbox--checked .za-checkbox__inner:before {
  border-color: #bcbcbc;
  border-color: var(--checkbox-disabled-color);
}

.za-checkbox--untext {
  line-height: 1;
}

.za-checkbox-group {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}
.za-checkbox-group__inner {
  margin-right: -24px;
  margin-bottom: -8px;
}

.za-checkbox-group .za-checkbox {
  margin-right: 24px;
  margin-bottom: 8px;
}
.za-checkbox-group--block {
  width: 100%;
}
.za-checkbox-group--block .za-checkbox-group__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.za-checkbox-group--block .za-checkbox {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-checkbox-group--button .za-checkbox-group__inner {
  margin-right: -8px;
  margin-bottom: -8px;
}

.za-checkbox-group--button .za-checkbox {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
  border-width: 1PX;
  border-style: solid;
  border-radius: 2px;
  border-radius: var(--radius-xs);
}
.za-checkbox-group--button .za-checkbox, .za-checkbox-group--button .za-checkbox:active, .za-checkbox-group--button .za-checkbox:hover, .za-checkbox-group--button .za-checkbox:focus, .za-checkbox-group--button .za-checkbox:visited {
  text-decoration: none;
}
.za-checkbox-group--button .za-checkbox.za-checkbox--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-checkbox-group--button .za-checkbox.za-checkbox--block {
  display: block;
  width: 100%;
}

.za-checkbox-group--button .za-checkbox, .za-checkbox-group--button .za-checkbox:hover {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-checkbox-group--button .za-checkbox:active {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-checkbox-group--button .za-checkbox.za-checkbox--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-checkbox-group--button .za-checkbox.za-checkbox--disabled:active, .za-checkbox-group--button .za-checkbox.za-checkbox--disabled:focus, .za-checkbox-group--button .za-checkbox.za-checkbox--disabled:visited {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}

.za-checkbox-group--button .za-checkbox.za-checkbox--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
          box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
}

.za-checkbox-group--button .za-checkbox .za-checkbox__text {
  margin: 0;
}

.za-checkbox-group--button .za-checkbox.za-checkbox--checked, .za-checkbox-group--button .za-checkbox.za-checkbox--checked:hover {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}
.za-checkbox-group--button .za-checkbox.za-checkbox--checked:active {
  background-color: hsl(156, 100%, 32.9%);
  background-color: var(--button-primary-active-background);
  border-color: hsl(156, 100%, 32.9%);
  border-color: var(--button-primary-active-border);
  color: rgba(255, 255, 255, 0.4);
  color: var(--button-primary-active-color);
}
.za-checkbox-group--button .za-checkbox.za-checkbox--checked.za-checkbox--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-checkbox-group--button .za-checkbox.za-checkbox--checked.za-checkbox--disabled:active, .za-checkbox-group--button .za-checkbox.za-checkbox--checked.za-checkbox--disabled:focus, .za-checkbox-group--button .za-checkbox.za-checkbox--checked.za-checkbox--disabled:visited {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}

.za-checkbox-group--button .za-checkbox.za-checkbox--checked.za-checkbox--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
          box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
}

.za-checkbox-group--button.za-checkbox-group--block .za-checkbox {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-checkbox-group--button.za-checkbox-group--rect .za-checkbox {
  border-width: 1PX;
  border-style: solid;
  border-radius: 0;
}

.za-checkbox-group--button.za-checkbox-group--radius .za-checkbox {
  border-width: 1PX;
  border-style: solid;
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-checkbox-group--button.za-checkbox-group--round .za-checkbox {
  border-width: 1PX;
  border-style: solid;
  border-radius: 1000px;
  border-radius: var(--radius-round);
}

.za-checkbox-group--button.za-checkbox-group--circle .za-checkbox {
  border-width: 1PX;
  border-style: solid;
  border-radius: 50%;
  border-radius: var(--radius-circle);
}

.za-checkbox-group--button.za-checkbox-group--lg .za-checkbox {
  padding: 0 20px;
  padding: 0 var(--button-padding-h-lg);
  font-size: 18px;
  font-size: var(--button-font-size-lg);
  height: 52px;
  height: var(--button-height-lg);
  line-height: 1.5;
}
.za-checkbox-group--button.za-checkbox-group--lg .za-checkbox.za-checkbox--circle {
  width: 52px;
  width: var(--button-height-lg);
  padding: 0;
}

.za-checkbox-group--button.za-checkbox-group--lg .za-checkbox .za-icon {
  font-size: 26px;
  font-size: var(--button-icon-size-lg);
}
.za-checkbox-group--button.za-checkbox-group--lg .za-checkbox.za-checkbox--link {
  line-height: 52px;
  line-height: var(--button-height-lg);
}

.za-checkbox-group--button.za-checkbox-group--lg .za-checkbox * + span {
  margin-left: calc(20px / 2);
  margin-left: calc(var(--button-padding-h-lg) / 2);
}

.za-checkbox-group--button.za-checkbox-group--md .za-checkbox {
  padding: 0 16px;
  padding: 0 var(--button-padding-h-md);
  font-size: 16px;
  font-size: var(--button-font-size-md);
  height: 44px;
  height: var(--button-height-md);
  line-height: 1.5;
}
.za-checkbox-group--button.za-checkbox-group--md .za-checkbox.za-checkbox--circle {
  width: 44px;
  width: var(--button-height-md);
  padding: 0;
}

.za-checkbox-group--button.za-checkbox-group--md .za-checkbox .za-icon {
  font-size: 22px;
  font-size: var(--button-icon-size-md);
}
.za-checkbox-group--button.za-checkbox-group--md .za-checkbox.za-checkbox--link {
  line-height: 44px;
  line-height: var(--button-height-md);
}

.za-checkbox-group--button.za-checkbox-group--md .za-checkbox * + span {
  margin-left: calc(16px / 2);
  margin-left: calc(var(--button-padding-h-md) / 2);
}

.za-checkbox-group--button.za-checkbox-group--sm .za-checkbox {
  padding: 0 12px;
  padding: 0 var(--button-padding-h-sm);
  font-size: 14px;
  font-size: var(--button-font-size-sm);
  height: 36px;
  height: var(--button-height-sm);
  line-height: 1.5;
}
.za-checkbox-group--button.za-checkbox-group--sm .za-checkbox.za-checkbox--circle {
  width: 36px;
  width: var(--button-height-sm);
  padding: 0;
}

.za-checkbox-group--button.za-checkbox-group--sm .za-checkbox .za-icon {
  font-size: 18px;
  font-size: var(--button-icon-size-sm);
}
.za-checkbox-group--button.za-checkbox-group--sm .za-checkbox.za-checkbox--link {
  line-height: 36px;
  line-height: var(--button-height-sm);
}

.za-checkbox-group--button.za-checkbox-group--sm .za-checkbox * + span {
  margin-left: calc(12px / 2);
  margin-left: calc(var(--button-padding-h-sm) / 2);
}

.za-checkbox-group--button.za-checkbox-group--xs .za-checkbox {
  padding: 0 8px;
  padding: 0 var(--button-padding-h-xs);
  font-size: 12px;
  font-size: var(--button-font-size-xs);
  height: 28px;
  height: var(--button-height-xs);
  line-height: 1.5;
}
.za-checkbox-group--button.za-checkbox-group--xs .za-checkbox.za-checkbox--circle {
  width: 28px;
  width: var(--button-height-xs);
  padding: 0;
}

.za-checkbox-group--button.za-checkbox-group--xs .za-checkbox .za-icon {
  font-size: 16px;
  font-size: var(--button-icon-size-xs);
}
.za-checkbox-group--button.za-checkbox-group--xs .za-checkbox.za-checkbox--link {
  line-height: 28px;
  line-height: var(--button-height-xs);
}

.za-checkbox-group--button.za-checkbox-group--xs .za-checkbox * + span {
  margin-left: calc(8px / 2);
  margin-left: calc(var(--button-padding-h-xs) / 2);
}

.za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked, .za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked:hover {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}
.za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked:active {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}
.za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked.za-checkbox--disabled, .za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked.za-checkbox--disabled:active, .za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked.za-checkbox--disabled:hover, .za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked.za-checkbox--disabled:focus, .za-checkbox-group--button.za-checkbox-group--ghost .za-checkbox.za-checkbox--checked.za-checkbox--disabled:visited {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
  cursor: not-allowed;
}

.za-checkbox-group--button.za-checkbox-group--compact .za-checkbox:not(:last-child) {
  margin-right: -1PX;
}
.za-checkbox-group--button.za-checkbox-group--compact .za-checkbox:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.za-checkbox-group--button.za-checkbox-group--compact .za-checkbox:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.za-checkbox-group--button.za-checkbox-group--compact .za-checkbox:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.za-checkbox-group--button.za-checkbox-group--compact .za-checkbox.za-checkbox--checked {
  z-index: 1;
}

.za-checkbox-group--cell {
  display: block;
  overflow: visible;
}
.za-checkbox-group--cell .za-checkbox-group__inner {
  margin-right: 0;
  margin-bottom: 0;
}

.za-checkbox-group--cell .za-checkbox {
  position: static;
  margin-right: 0;
  margin-bottom: 0;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-collapse {
  position: relative;
}
.za-collapse:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-collapse:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-collapse--animated .za-collapse-item__content {
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.za-collapse-item {
  font-size: 15px;
}
.za-collapse-item__header {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 52px;
  min-height: var(--collapse-height);
  padding: 15px 16px;
  padding: var(--collapse-padding-v) var(--collapse-padding-h);
}
.za-collapse-item__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-collapse-item__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-collapse-item__header:after {
  left: 16px;
  left: var(--cell-line-padding-left);
}

.za-collapse-item__title {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-collapse-item__content {
  position: relative;
  height: 0;
  overflow: hidden;
  font-size: 13px;
  color: #909090;
  color: var(--color-text-caption);
  will-change: height;
}
.za-collapse-item__content:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-collapse-item__content:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-collapse-item__content:after {
  left: 16px;
  left: var(--cell-line-padding-left);
}

.za-collapse-item__content__inner {
  padding: 12px 16px;
  padding: var(--padding-v-md) var(--padding-h-md);
}

.za-collapse-item__arrow {
  margin-top: -3px;
  margin-left: 5px;
  margin-right: 2px;
}
.za-collapse-item__arrow:after {
  display: inline-block;
  content: "";
  border-right: 2px solid #bcbcbc;
  border-right: var(--cell-arrow-border-width) solid var(--cell-arrow-color);
  border-top: 2px solid #bcbcbc;
  border-top: var(--cell-arrow-border-width) solid var(--cell-arrow-color);
  width: 10px;
  width: var(--cell-arrow-length);
  height: 10px;
  height: var(--cell-arrow-length);
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.za-collapse-item--disabled .za-collapse-item__title {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

.za-collapse-item--disabled .za-collapse-item__arrow:after {
  border-right-color: #eee;
  border-right-color: var(--collapse-arrow-disabled-color);
  border-top-color: #eee;
  border-top-color: var(--collapse-arrow-disabled-color);
}

.za-collapse-item--active .za-collapse-item__content {
  height: auto;
}

.za-collapse-item--active .za-collapse-item__arrow {
  margin-top: 0;
}
.za-collapse-item--active .za-collapse-item__arrow:after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-confirm .za-modal__body {
  text-align: center;
}

.za-confirm .za-modal__footer {
  padding: 0;
}

.za-confirm .za-popup {
  position: relative;
  background-color: #fff;
  background-color: var(--modal-background);
  -webkit-box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
          box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
  -webkit-box-shadow: 0 7px 21px var(--color-shadow);
          box-shadow: 0 7px 21px var(--color-shadow);
  overflow: hidden;
}
.za-confirm__button {
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
  padding: 0 20px;
  padding: 0 var(--button-padding-h-lg);
  font-size: 18px;
  font-size: var(--button-font-size-lg);
  height: 52px;
  height: var(--button-height-lg);
  line-height: 1.5;
  background-color: #fff;
  background-color: var(--confirm-button-background);
}
.za-confirm__button, .za-confirm__button:active, .za-confirm__button:hover, .za-confirm__button:focus, .za-confirm__button:visited {
  text-decoration: none;
}
.za-confirm__button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-confirm__button--block {
  display: block;
  width: 100%;
}

.za-confirm__button--circle {
  width: 52px;
  width: var(--button-height-lg);
  padding: 0;
}

.za-confirm__button .za-icon {
  font-size: 26px;
  font-size: var(--button-icon-size-lg);
}
.za-confirm__button--link {
  line-height: 52px;
  line-height: var(--button-height-lg);
}

.za-confirm__button * + span {
  margin-left: calc(20px / 2);
  margin-left: calc(var(--button-padding-h-lg) / 2);
}
.za-confirm__button:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-confirm__button:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-confirm__button:active {
  background-color: #e6e6e6;
  background-color: var(--confirm-button-active-background);
}
.za-confirm__button:not(:first-of-type):after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-left: 1PX solid #dcdcdc;
  border-left: 1PX solid var(--border-color);
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-confirm__button:not(:first-of-type):after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-confirm__button--ok {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-confirm--radius .za-popup {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-date-picker__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  height: 45px;
  height: var(--picker-header-height);
  line-height: 45px;
  line-height: var(--picker-header-height);
  font-size: 16px;
  font-size: var(--picker-header-font-size);
  background: #f7f7f7;
  background: var(--picker-header-background);
}
.za-date-picker__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-date-picker__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-date-picker__cancel, .za-date-picker__submit {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0 20px;
  cursor: pointer;
}

.za-date-picker__cancel {
  text-align: left;
  color: #909090;
  color: var(--color-text-caption);
}

.za-date-picker__submit {
  text-align: right;
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-date-picker__title {
  color: #343434;
  color: var(--color-text);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-date-picker-view {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 210px;
  padding: 20px 0;
  background: #fff;
  background: var(--picker-background);
}
.za-date-picker-view__mask {
  width: 100%;
  height: 88px;
  pointer-events: none;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.za-date-picker-view__mask--top {
  position: absolute;
  top: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.8)));
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: -webkit-gradient(linear, left bottom, left top, from(var(--picker-mask-background-start)), to(var(--picker-mask-background-end)));
  background: -webkit-linear-gradient(bottom, var(--picker-mask-background-start), var(--picker-mask-background-end));
  background: linear-gradient(to top, var(--picker-mask-background-start), var(--picker-mask-background-end));
}
.za-date-picker-view__mask--top:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-date-picker-view__mask--top:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-date-picker-view__mask--bottom {
  position: absolute;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.8)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: -webkit-gradient(linear, left top, left bottom, from(var(--picker-mask-background-start)), to(var(--picker-mask-background-end)));
  background: -webkit-linear-gradient(top, var(--picker-mask-background-start), var(--picker-mask-background-end));
  background: linear-gradient(to bottom, var(--picker-mask-background-start), var(--picker-mask-background-end));
}
.za-date-picker-view__mask--bottom:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-date-picker-view__mask--bottom:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-date-picker-view__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-date-select {
  width: 100%;
  height: 100%;
}
.za-date-select__input {
  position: relative;
  width: 100%;
  padding: 12px 0;
  line-height: 28px;
}

.za-date-select--placeholder .za-date-select__input {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-date-select--arrow .za-date-select__input {
  padding-right: 20px;
}
.za-date-select--arrow .za-date-select__input:after {
  content: "";
  position: absolute;
  width: 10px;
  width: var(--cell-arrow-length);
  height: 10px;
  height: var(--cell-arrow-length);
  top: calc(15px + 3px);
  top: calc(var(--cell-padding-v) + 3px);
  right: 2px;
  border-right: 2px solid #bcbcbc;
  border-right: 2px solid var(--cell-arrow-color);
  border-bottom: 2px solid #bcbcbc;
  border-bottom: 2px solid var(--cell-arrow-color);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.za-date-select--disabled .za-date-select__input {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-file-picker__input {
  display: none;
}

.za-file-picker--disabled {
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* icon themes */
  /* icon sizes */
}
.za-icon[type=search] {
  -webkit-appearance: initial !important;
}
.za-icon--primary {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-icon--success {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-success);
}

.za-icon--warning {
  color: hsl(31, 83.1%, 55.9%);
  color: var(--theme-warning);
}

.za-icon--danger {
  color: hsl(0, 100%, 65.7%);
  color: var(--theme-danger);
}

.za-icon--sm {
  font-size: 16px;
  font-size: var(--icon-font-size-sm);
}

.za-icon--md {
  font-size: 24px;
  font-size: var(--icon-font-size-md);
}

.za-icon--lg {
  font-size: 32px;
  font-size: var(--icon-font-size-lg);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
@-webkit-keyframes virtual-cursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes virtual-cursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.za-input {
  position: relative;
  width: 100%;
  color: #343434;
  color: var(--color-text);
}
.za-input input,
.za-input textarea {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  color: #343434;
  color: var(--color-text);
  line-height: 22px;
  line-height: var(--input-line-height);
}
.za-input input::-webkit-input-placeholder, .za-input textarea::-webkit-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input input::-moz-placeholder, .za-input textarea::-moz-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input input:-ms-input-placeholder, .za-input textarea:-ms-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input input::-ms-input-placeholder, .za-input textarea::-ms-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input input::placeholder,
.za-input textarea::placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input input {
  display: inline-block;
  height: 22px;
  height: var(--input-line-height);
}
.za-input textarea {
  display: block;
  resize: none;
  word-break: break-all;
  word-wrap: break-word;
  overflow: visible;
}
.za-input__content {
  position: relative;
  height: 22px;
  height: var(--input-line-height);
  line-height: 22px;
  line-height: var(--input-line-height);
}

.za-input__clear {
  position: absolute;
  visibility: hidden;
  color: #bcbcbc;
  color: var(--input-clear-icon-color);
  width: 16px;
  width: var(--input-clear-icon-width);
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  font-size: 16px;
}

.za-input--focus {
  padding-right: 16px;
  padding-right: var(--input-clear-icon-width);
}
.za-input--focus .za-input__clear {
  visibility: visible;
}

.za-input::-webkit-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-input::-moz-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-input:-ms-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-input::-ms-input-placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-input::placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}
.za-input__placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-input--number .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.za-input--number.za-input--focus .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.za-input--number.za-input--focus .za-input__virtual-input:after {
  content: "";
  display: inline-block;
  height: calc(22px - 4px);
  height: var(--input-cursor-height);
  border-right: 2px solid #597cf6;
  border-right: var(--input-cursor-width) solid var(--input-cursor-color);
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-animation: virtual-cursor 1s infinite step-start;
          animation: virtual-cursor 1s infinite step-start;
}

.za-input--number.za-input--readonly .za-input__virtual-input {
  position: static;
  white-space: normal;
}

.za-input--price .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.za-input--price.za-input--focus .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.za-input--price.za-input--focus .za-input__virtual-input:after {
  content: "";
  display: inline-block;
  height: calc(22px - 4px);
  height: var(--input-cursor-height);
  border-right: 2px solid #597cf6;
  border-right: var(--input-cursor-width) solid var(--input-cursor-color);
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-animation: virtual-cursor 1s infinite step-start;
          animation: virtual-cursor 1s infinite step-start;
}

.za-input--price.za-input--readonly .za-input__virtual-input {
  position: static;
  white-space: normal;
}

.za-input--idcard .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.za-input--idcard.za-input--focus .za-input__virtual-input {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.za-input--idcard.za-input--focus .za-input__virtual-input:after {
  content: "";
  display: inline-block;
  height: calc(22px - 4px);
  height: var(--input-cursor-height);
  border-right: 2px solid #597cf6;
  border-right: var(--input-cursor-width) solid var(--input-cursor-color);
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-animation: virtual-cursor 1s infinite step-start;
          animation: virtual-cursor 1s infinite step-start;
}

.za-input--idcard.za-input--readonly .za-input__virtual-input {
  position: static;
  white-space: normal;
}

.za-input--textarea .za-input__length {
  text-align: right;
  color: #bcbcbc;
  color: var(--input-length-count-color);
  font-size: 12px;
}

.za-input--clearable .za-input__clear {
  position: absolute;
  visibility: hidden;
  color: #bcbcbc;
  color: var(--input-clear-icon-color);
  width: 16px;
  width: var(--input-clear-icon-width);
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  font-size: 16px;
}

.za-input--clearable.za-input--focus {
  padding-right: 16px;
  padding-right: var(--input-clear-icon-width);
}
.za-input--clearable.za-input--focus .za-input__clear {
  visibility: visible;
}

.za-input--readonly .za-input__content {
  height: auto;
}

.za-input--disabled input,
.za-input--disabled textarea {
  cursor: not-allowed;
  color: #bcbcbc;
  color: var(--color-text-disabled);
  -webkit-text-fill-color: #bcbcbc;
  -webkit-text-fill-color: var(--color-text-disabled);
  opacity: 1;
}
.za-input--disabled input::-webkit-input-placeholder, .za-input--disabled textarea::-webkit-input-placeholder {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-input--disabled input::-moz-placeholder, .za-input--disabled textarea::-moz-placeholder {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-input--disabled input:-ms-input-placeholder, .za-input--disabled textarea:-ms-input-placeholder {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-input--disabled input::-ms-input-placeholder, .za-input--disabled textarea::-ms-input-placeholder {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-input--disabled input::placeholder,
.za-input--disabled textarea::placeholder {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}
.za-input--disabled .za-input__content, .za-input--disabled .za-input__placeholder {
  cursor: not-allowed;
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-keyboard {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: #fff;
  background-color: var(--keyboard-background);
  color: #343434;
  color: var(--color-text);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.za-keyboard__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  font-size: 30px;
  font-size: var(--keyboard-item-font-size);
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
}
.za-keyboard__item, .za-keyboard__item:active, .za-keyboard__item:hover, .za-keyboard__item:focus, .za-keyboard__item:visited {
  text-decoration: none;
}
.za-keyboard__item--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-keyboard__item--block {
  display: block;
  width: 100%;
}

.za-keyboard__item, .za-keyboard__item:hover {
  background-color: #fff;
  background-color: var(--keyboard-item-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-keyboard__item:active {
  background-color: #e6e6e6;
  background-color: var(--button-default-active-background);
  border-color: #e6e6e6;
  border-color: var(--button-default-active-border);
  color: #343434;
  color: var(--button-default-active-color);
}
.za-keyboard__item--disabled {
  opacity: 1;
  cursor: not-allowed;
}
.za-keyboard__item--disabled:active, .za-keyboard__item--disabled:focus, .za-keyboard__item--disabled:visited {
  background-color: #fff;
  background-color: var(--keyboard-item-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}

.za-keyboard__item--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(hsl(0, 0%, 90%), 0.3);
          box-shadow: 0 3px 3px 0 rgba(hsl(0, 0%, 90%), 0.3);
  -webkit-box-shadow: 0 3px 3px 0 rgba(var(--theme-default), 0.3);
          box-shadow: 0 3px 3px 0 rgba(var(--theme-default), 0.3);
}

.za-keyboard__item--ok {
  font-size: 21px;
}
.za-keyboard__item--ok, .za-keyboard__item--ok:hover {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}
.za-keyboard__item--ok:active {
  background-color: hsl(156, 100%, 32.9%);
  background-color: var(--button-primary-active-background);
  border-color: hsl(156, 100%, 32.9%);
  border-color: var(--button-primary-active-border);
  color: rgba(255, 255, 255, 0.4);
  color: var(--button-primary-active-color);
}
.za-keyboard__item--ok.za-keyboard--disabled {
  opacity: 1;
  cursor: not-allowed;
}
.za-keyboard__item--ok.za-keyboard--disabled:active, .za-keyboard__item--ok.za-keyboard--disabled:focus, .za-keyboard__item--ok.za-keyboard--disabled:visited {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}

.za-keyboard__item--ok.za-keyboard--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(hsl(0, 0%, 90%), 0.3);
          box-shadow: 0 3px 3px 0 rgba(hsl(0, 0%, 90%), 0.3);
  -webkit-box-shadow: 0 3px 3px 0 rgba(var(--theme-default), 0.3);
          box-shadow: 0 3px 3px 0 rgba(var(--theme-default), 0.3);
}

.za-keyboard__item--disabled, .za-keyboard__item--disabled:hover, .za-keyboard__item--disabled:active {
  background-color: #fff;
  background-color: var(--keyboard-background);
}

.za-keyboard__keys {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 75%;
}
.za-keyboard__keys .za-keyboard__item {
  width: 33.333333%;
  height: 52px;
  height: var(--keyboard-item-height);
}
.za-keyboard__keys .za-keyboard__item:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
  border-left: 1PX solid #dcdcdc;
  border-left: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-keyboard__keys .za-keyboard__item:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-keyboard__keys .za-keyboard__item:nth-of-type(3n+1):after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-keyboard__keys .za-keyboard__item:nth-of-type(3n+1):after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-keyboard__handle {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 25%;
}
.za-keyboard__handle .za-keyboard__item {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.za-keyboard__handle .za-keyboard__item:first-of-type:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
  border-left: 1PX solid #dcdcdc;
  border-left: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-keyboard__handle .za-keyboard__item:first-of-type:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-loading {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.za-loading--open {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.za-loading__container {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 80px;
  height: 80px;
  padding: 20px 15px;
  border-radius: 4px;
  border-radius: var(--radius-md);
  background: rgba(0, 0, 0, 0.8);
  background: var(--loading-background);
  color: #343434;
  color: var(--color-text);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-marquee {
  width: 100%;
  overflow: hidden;
}
.za-marquee__body {
  white-space: nowrap;
}

.za-marquee__content {
  display: inline-block;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, var(--opacity-mask));
  z-index: 1000;
  z-index: var(--zindex-mask);
}
.za-mask--transparent {
  background-color: transparent;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-message {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 6px 8px;
  padding: var(--message-padding-v-md) var(--message-padding-h-md);
  min-height: 29px;
  min-height: var(--message-min-height-md);
  font-size: 12px;
  font-size: var(--message-font-size-md);
  color: hsl(0, 0%, 90%);
  color: var(--theme-default);
  background-color: hsla(0, 0%, 90%, 0.1);
  background-color: hsla(var(--theme-default-h), var(--theme-default-s), var(--theme-default-l), 0.1);
}
.za-message__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
}

.za-message__icon {
  margin-right: 4px;
}
.za-message__icon .za-icon {
  font-size: 14px;
  font-size: var(--message-icon-size-md);
  cursor: pointer;
}

.za-message__body {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.za-message__footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: right;
  padding-left: 10px;
}
.za-message__footer .za-icon {
  font-size: 14px;
  font-size: var(--message-icon-size-md);
  cursor: pointer;
}

.za-message--primary {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
  background-color: hsla(156, 100%, 36.9%, 0.1);
  background-color: hsla(var(--theme-primary-h), var(--theme-primary-s), var(--theme-primary-l), 0.1);
}

.za-message--success {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-success);
  background-color: hsla(156, 100%, 36.9%, 0.1);
  background-color: hsla(var(--theme-success-h), var(--theme-success-s), var(--theme-success-l), 0.1);
}

.za-message--warning {
  color: hsl(31, 83.1%, 55.9%);
  color: var(--theme-warning);
  background-color: hsla(31, 83.1%, 55.9%, 0.1);
  background-color: hsla(var(--theme-warning-h), var(--theme-warning-s), var(--theme-warning-l), 0.1);
}

.za-message--danger {
  color: hsl(0, 100%, 65.7%);
  color: var(--theme-danger);
  background-color: hsla(0, 100%, 65.7%, 0.1);
  background-color: hsla(var(--theme-danger-h), var(--theme-danger-s), var(--theme-danger-l), 0.1);
}

.za-message--lg {
  min-height: 40px;
  min-height: var(--message-min-height-lg);
  font-size: 14px;
  font-size: var(--message-font-size-lg);
  padding: 8px 12px;
  padding: var(--message-padding-v-lg) var(--message-padding-h-lg);
}
.za-message--lg .za-message__icon {
  margin-right: 6px;
}
.za-message--lg .za-message__icon .za-icon {
  font-size: 16px;
  font-size: var(--message-icon-size-lg);
}

.za-message--lg .za-message__body {
  font-size: 14px;
  font-size: var(--message-font-size-lg);
}

.za-message--lg .za-message__footer .za-icon {
  font-size: 16px;
  font-size: var(--message-icon-size-lg);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-modal {
  -webkit-overflow-scrolling: touch;
}
.za-modal .za-popup {
  position: relative;
  background-color: #fff;
  background-color: var(--modal-background);
  -webkit-box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
          box-shadow: 0 7px 21px rgba(56, 56, 56, 0.15);
  -webkit-box-shadow: 0 7px 21px var(--color-shadow);
          box-shadow: 0 7px 21px var(--color-shadow);
  overflow: hidden;
}
.za-modal--radius .za-popup {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-modal__header {
  position: relative;
  padding: 20px 15px 0 15px;
  text-align: center;
}
.za-modal__header__title {
  font-size: 18px;
  font-size: var(--modal-title-font-size);
  line-height: 1;
  color: #343434;
  color: var(--modal-title-color);
}
.za-modal__header__close {
  position: absolute;
  top: 20px;
  right: 15px;
  line-height: 15px;
  color: #ccc;
  color: var(--modal-close-color);
  font-size: 20px;
  cursor: pointer;
}
.za-modal__header__close:hover {
  color: #999;
  color: var(--modal-close-active-color);
}

.za-modal__body {
  font-size: 15px;
  color: #909090;
  color: var(--color-text-caption);
  padding: 20px;
  overflow: auto;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.za-modal__footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px 20px;
  width: 100%;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-nav-bar {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 44px;
  height: var(--nav-bar-height);
  background: #fdfdfd;
  background: var(--nav-bar-color);
}
.za-nav-bar__title {
  position: absolute;
  left: 50%;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.za-nav-bar__side {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.za-nav-bar__side--left {
  margin-left: 16px;
}

.za-nav-bar__side--right {
  margin-right: 16px;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-notice-bar {
  position: relative;
  width: 100%;
  height: 24px;
  height: var(--notice-bar-height);
  line-height: 24px;
  line-height: var(--notice-bar-height);
  overflow: hidden;
}
.za-notice-bar__body {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-panel__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 16px 7px 16px;
  font-size: 14px;
  font-size: var(--panel-header-font-size);
  color: #909090;
  color: var(--panel-header-color);
}
.za-panel__header__title {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-panel__body {
  position: relative;
  background: #fff;
  background: var(--panel-body-background);
  font-size: 15px;
  font-size: var(--panel-body-font-size);
  color: #343434;
  color: var(--color-text);
}
.za-panel__body:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-panel__body:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-picker__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  height: 45px;
  height: var(--picker-header-height);
  line-height: 45px;
  line-height: var(--picker-header-height);
  font-size: 16px;
  font-size: var(--picker-header-font-size);
  background: #f7f7f7;
  background: var(--picker-header-background);
}
.za-picker__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-picker__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-picker__cancel, .za-picker__submit {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0 20px;
  cursor: pointer;
}

.za-picker__cancel {
  text-align: left;
  color: #909090;
  color: var(--color-text-caption);
}

.za-picker__submit {
  text-align: right;
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-picker__title {
  color: #343434;
  color: var(--color-text);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-picker-view {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 210px;
  padding: 20px 0;
  background: #fff;
  background: var(--picker-background);
}
.za-picker-view__mask {
  width: 100%;
  height: 88px;
  pointer-events: none;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.za-picker-view__mask--top {
  position: absolute;
  top: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.8)));
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: -webkit-gradient(linear, left bottom, left top, from(var(--picker-mask-background-start)), to(var(--picker-mask-background-end)));
  background: -webkit-linear-gradient(bottom, var(--picker-mask-background-start), var(--picker-mask-background-end));
  background: linear-gradient(to top, var(--picker-mask-background-start), var(--picker-mask-background-end));
}
.za-picker-view__mask--top:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-picker-view__mask--top:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-picker-view__mask--bottom {
  position: absolute;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.8)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8));
  background: -webkit-gradient(linear, left top, left bottom, from(var(--picker-mask-background-start)), to(var(--picker-mask-background-end)));
  background: -webkit-linear-gradient(top, var(--picker-mask-background-start), var(--picker-mask-background-end));
  background: linear-gradient(to bottom, var(--picker-mask-background-start), var(--picker-mask-background-end));
}
.za-picker-view__mask--bottom:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-picker-view__mask--bottom:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-picker-view__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-popup {
  z-index: 1100;
  z-index: var(--zindex-popup);
  -webkit-overflow-scrolling: touch;
  margin: auto;
}
.za-popup__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1000;
  z-index: var(--zindex-mask);
  overflow: auto;
  padding: 60px 0;
  -webkit-overflow-scrolling: touch;
}

.za-popup--hidden {
  opacity: 0;
  visibility: hidden;
}

.za-popup--nomask {
  position: fixed;
}
.za-popup--nomask.za-popup--center {
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.za-popup--bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-transform: translate(0, 100%);
      -ms-transform: translate(0, 100%);
          transform: translate(0, 100%);
}

.za-popup--top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
          transform: translate(0, -100%);
}

.za-popup--left {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  -webkit-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.za-popup--right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  -webkit-transform: translate(100%, 0);
      -ms-transform: translate(100%, 0);
          transform: translate(100%, 0);
}

.za-popup--show.za-popup--bottom {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.za-popup--show.za-popup--top {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.za-popup--show.za-popup--left {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.za-popup--show.za-popup--right {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-progress {
  display: inline-block;
  position: relative;
}
.za-progress__outer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.za-progress__text {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}

.za-progress--line.za-progress--primary .za-progress__thumb {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}

.za-progress--line.za-progress--success .za-progress__thumb {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-success);
}

.za-progress--line.za-progress--warning .za-progress__thumb {
  background-color: hsl(31, 83.1%, 55.9%);
  background-color: var(--theme-warning);
}

.za-progress--line.za-progress--danger .za-progress__thumb {
  background-color: hsl(0, 100%, 65.7%);
  background-color: var(--theme-danger);
}

.za-progress--line.za-progress--lg {
  width: 100%;
  width: var(--progress-width-lg);
}

.za-progress--line.za-progress--md {
  width: 100%;
  width: var(--progress-width-md);
}

.za-progress--line.za-progress--sm {
  width: 140px;
  width: var(--progress-width-sm);
}

.za-progress--line .za-progress__track {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  background: var(--progress-background);
  overflow: hidden;
}

.za-progress--line .za-progress__thumb {
  height: 100%;
  -webkit-transition: width 0.6s ease, background-color 0.6s ease;
  transition: width 0.6s ease, background-color 0.6s ease;
}

.za-progress--circle {
  display: inline-block;
}
.za-progress--circle .za-progress__track {
  stroke: #f2f2f2;
  stroke: var(--progress-background);
  fill-opacity: 0;
}

.za-progress--circle .za-progress__thumb {
  fill-opacity: 0;
  -webkit-transition: stroke-dashoffset 0.6s ease, stroke 0.6s ease;
  transition: stroke-dashoffset 0.6s ease, stroke 0.6s ease;
}

.za-progress--circle.za-progress--primary .za-progress__thumb {
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-primary);
}

.za-progress--circle.za-progress--success .za-progress__thumb {
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-success);
}

.za-progress--circle.za-progress--warning .za-progress__thumb {
  stroke: hsl(31, 83.1%, 55.9%);
  stroke: var(--theme-warning);
}

.za-progress--circle.za-progress--danger .za-progress__thumb {
  stroke: hsl(0, 100%, 65.7%);
  stroke: var(--theme-danger);
}

.za-progress--circle svg {
  width: 100%;
  height: 100%;
}
.za-progress--circle .za-progress__text {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
  line-height: 1;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.za-progress--circle.za-progress--lg {
  width: 200px;
  width: var(--progress-size-lg);
  height: 200px;
  height: var(--progress-size-lg);
}
.za-progress--circle.za-progress--lg .za-progress__text {
  font-size: 30px;
  font-size: var(--progress-font-size-circle-lg);
}

.za-progress--circle.za-progress--md {
  width: 150px;
  width: var(--progress-size-md);
  height: 150px;
  height: var(--progress-size-md);
}
.za-progress--circle.za-progress--md .za-progress__text {
  font-size: 24px;
  font-size: var(--progress-font-size-circle-md);
}

.za-progress--circle.za-progress--sm {
  width: 80px;
  width: var(--progress-size-sm);
  height: 80px;
  height: var(--progress-size-sm);
}
.za-progress--circle.za-progress--sm .za-progress__text {
  font-size: 18px;
  font-size: var(--progress-font-size-circle-sm);
}

.za-progress--semi-circle {
  display: inline-block;
}
.za-progress--semi-circle .za-progress__track {
  stroke: #f2f2f2;
  stroke: var(--progress-background);
  fill-opacity: 0;
}

.za-progress--semi-circle .za-progress__thumb {
  fill-opacity: 0;
  -webkit-transition: stroke-dashoffset 0.6s ease, stroke 0.6s ease;
  transition: stroke-dashoffset 0.6s ease, stroke 0.6s ease;
}

.za-progress--semi-circle.za-progress--primary .za-progress__thumb {
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-primary);
}

.za-progress--semi-circle.za-progress--success .za-progress__thumb {
  stroke: hsl(156, 100%, 36.9%);
  stroke: var(--theme-success);
}

.za-progress--semi-circle.za-progress--warning .za-progress__thumb {
  stroke: hsl(31, 83.1%, 55.9%);
  stroke: var(--theme-warning);
}

.za-progress--semi-circle.za-progress--danger .za-progress__thumb {
  stroke: hsl(0, 100%, 65.7%);
  stroke: var(--theme-danger);
}

.za-progress--semi-circle svg {
  width: 100%;
  height: 100%;
}
.za-progress--semi-circle .za-progress__text {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
  line-height: 1;
}

.za-progress--semi-circle.za-progress--lg {
  width: 200px;
  width: var(--progress-size-lg);
  height: calc(200px / 2);
  height: calc(var(--progress-size-lg) / 2);
}
.za-progress--semi-circle.za-progress--lg .za-progress__text {
  font-size: 30px;
  font-size: var(--progress-font-size-circle-lg);
}

.za-progress--semi-circle.za-progress--md {
  width: 150px;
  width: var(--progress-size-md);
  height: calc(150px / 2);
  height: calc(var(--progress-size-md) / 2);
}
.za-progress--semi-circle.za-progress--md .za-progress__text {
  font-size: 24px;
  font-size: var(--progress-font-size-circle-md);
}

.za-progress--semi-circle.za-progress--sm {
  width: 80px;
  width: var(--progress-size-sm);
  height: calc(80px / 2);
  height: calc(var(--progress-size-sm) / 2);
}
.za-progress--semi-circle.za-progress--sm .za-progress__text {
  font-size: 18px;
  font-size: var(--progress-font-size-circle-sm);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-pull {
  overflow: hidden;
  min-height: 50px;
  min-height: var(--pull-control-height);
}
.za-pull__content {
  will-change: transform;
  -webkit-transform: translate3d(0, 50px, 0);
          transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, var(--pull-control-height), 0);
          transform: translate3d(0, var(--pull-control-height), 0);
}

.za-pull__refresh, .za-pull__load {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  width: 100%;
}

.za-pull__refresh {
  margin-top: calc(50px * -1);
  margin-top: calc(var(--pull-control-height) * -1);
  height: 50px;
  height: var(--pull-control-height);
}

.za-pull__load {
  height: 0;
}
.za-pull__load--show {
  height: 50px;
  height: var(--pull-control-height);
}

.za-pull__control {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0;
  height: 50px;
  height: var(--pull-control-height);
  color: #909090;
  color: var(--color-text-caption);
  font-size: 14px;
  font-size: var(--pull-control-font-size);
}
.za-pull__control .za-icon {
  font-size: 22px;
}
.za-pull__control * + span {
  margin-left: 8px;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-radio {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #343434;
  color: var(--color-text);
  font-size: 14px;
}
.za-radio:not(.za-radio--disabled):hover .za-radio__inner {
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--radio-active-border-color);
}

.za-radio__widget {
  display: inline-block;
  vertical-align: middle;
}

.za-radio__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.za-radio__text {
  vertical-align: middle;
  margin: 0 8px;
}

.za-radio__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 18px;
  width: var(--radio-size);
  height: 18px;
  height: var(--radio-size);
  border: 1PX solid #dcdcdc;
  border: 1PX solid var(--radio-border-color);
  border-radius: 50%;
  border-radius: var(--radio-radius);
  background: #fff;
  background: var(--radio-background);
  -webkit-transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.za-radio__inner:before {
  content: "";
  width: calc(18px / 3.5);
  width: calc(var(--radio-size) / 3.5);
  height: calc(18px / 2);
  height: calc(var(--radio-size) / 2);
  border: calc(18px / 9) solid #fff;
  border: calc(var(--radio-size) / 9) solid var(--radio-arrow-color);
  border-top: 0;
  border-left: 0;
  margin-top: calc(18px / -12);
  margin-top: calc(var(--radio-size) / -12);
  margin-left: calc(18px / 18);
  margin-left: calc(var(--radio-size) / 18);
  -webkit-transform: rotate(45deg) scale(0);
      -ms-transform: rotate(45deg) scale(0);
          transform: rotate(45deg) scale(0);
  -webkit-transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.za-radio--checked .za-radio__inner {
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}
.za-radio--checked .za-radio__inner:before {
  -webkit-transform: rotate(45deg) scale(1);
      -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.za-radio--disabled .za-radio__input {
  cursor: not-allowed;
}

.za-radio--disabled .za-radio__text {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

.za-radio--disabled .za-radio__inner {
  background-color: #f2f2f2;
  background-color: var(--radio-disabled-background);
}

.za-radio--disabled.za-radio--checked .za-radio__inner {
  border-color: #dcdcdc;
  border-color: var(--radio-border-color);
  background-color: #f2f2f2;
  background-color: var(--radio-disabled-background);
}
.za-radio--disabled.za-radio--checked .za-radio__inner:before {
  border-color: #bcbcbc;
  border-color: var(--radio-disabled-color);
}

.za-radio--untext {
  line-height: 1;
}

.za-radio-group {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}
.za-radio-group__inner {
  margin-right: -24px;
  margin-bottom: -8px;
}

.za-radio-group .za-radio {
  margin-right: 24px;
  margin-bottom: 8px;
}
.za-radio-group--block {
  width: 100%;
}
.za-radio-group--block .za-radio-group__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.za-radio-group--block .za-radio {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-radio-group--button .za-radio-group__inner {
  margin-right: -8px;
  margin-bottom: -8px;
}

.za-radio-group--button .za-radio {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0;
  border: none;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  color: #343434;
  color: var(--color-text);
  outline: 0 none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  /* disabled Button */
  /* block Button */
  border-width: 1PX;
  border-style: solid;
  border-radius: 2px;
  border-radius: var(--radius-xs);
}
.za-radio-group--button .za-radio, .za-radio-group--button .za-radio:active, .za-radio-group--button .za-radio:hover, .za-radio-group--button .za-radio:focus, .za-radio-group--button .za-radio:visited {
  text-decoration: none;
}
.za-radio-group--button .za-radio.za-radio--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-radio-group--button .za-radio.za-radio--block {
  display: block;
  width: 100%;
}

.za-radio-group--button .za-radio, .za-radio-group--button .za-radio:hover {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-radio-group--button .za-radio:active {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}
.za-radio-group--button .za-radio.za-radio--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-radio-group--button .za-radio.za-radio--disabled:active, .za-radio-group--button .za-radio.za-radio--disabled:focus, .za-radio-group--button .za-radio.za-radio--disabled:visited {
  background-color: #fff;
  background-color: var(--button-default-background);
  border-color: hsl(0, 0%, 90%);
  border-color: var(--button-default-border);
  color: #343434;
  color: var(--button-default-color);
}

.za-radio-group--button .za-radio.za-radio--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
          box-shadow: 0 3px 3px 0 rgba(230, 230, 230, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-default-shadow-color);
}

.za-radio-group--button .za-radio .za-radio__text {
  margin: 0;
}

.za-radio-group--button .za-radio.za-radio--checked, .za-radio-group--button .za-radio.za-radio--checked:hover {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}
.za-radio-group--button .za-radio.za-radio--checked:active {
  background-color: hsl(156, 100%, 32.9%);
  background-color: var(--button-primary-active-background);
  border-color: hsl(156, 100%, 32.9%);
  border-color: var(--button-primary-active-border);
  color: rgba(255, 255, 255, 0.4);
  color: var(--button-primary-active-color);
}
.za-radio-group--button .za-radio.za-radio--checked.za-radio--disabled {
  opacity: 0.5;
  opacity: var(--button-disabled-opacity);
  cursor: not-allowed;
}
.za-radio-group--button .za-radio.za-radio--checked.za-radio--disabled:active, .za-radio-group--button .za-radio.za-radio--checked.za-radio--disabled:focus, .za-radio-group--button .za-radio.za-radio--checked.za-radio--disabled:visited {
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--button-primary-background);
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--button-primary-border);
  color: #fff;
  color: var(--button-primary-color);
}

.za-radio-group--button .za-radio.za-radio--checked.za-radio--shadow {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
          box-shadow: 0 3px 3px 0 rgba(0, 188, 112, 0.3);
  -webkit-box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
          box-shadow: 0 3px 3px 0 var(--button-primary-shadow-color);
}

.za-radio-group--button.za-radio-group--block .za-radio {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-radio-group--button.za-radio-group--rect .za-radio {
  border-width: 1PX;
  border-style: solid;
  border-radius: 0;
}

.za-radio-group--button.za-radio-group--radius .za-radio {
  border-width: 1PX;
  border-style: solid;
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-radio-group--button.za-radio-group--round .za-radio {
  border-width: 1PX;
  border-style: solid;
  border-radius: 1000px;
  border-radius: var(--radius-round);
}

.za-radio-group--button.za-radio-group--circle .za-radio {
  border-width: 1PX;
  border-style: solid;
  border-radius: 50%;
  border-radius: var(--radius-circle);
}

.za-radio-group--button.za-radio-group--lg .za-radio {
  padding: 0 20px;
  padding: 0 var(--button-padding-h-lg);
  font-size: 18px;
  font-size: var(--button-font-size-lg);
  height: 52px;
  height: var(--button-height-lg);
  line-height: 1.5;
}
.za-radio-group--button.za-radio-group--lg .za-radio.za-radio--circle {
  width: 52px;
  width: var(--button-height-lg);
  padding: 0;
}

.za-radio-group--button.za-radio-group--lg .za-radio .za-icon {
  font-size: 26px;
  font-size: var(--button-icon-size-lg);
}
.za-radio-group--button.za-radio-group--lg .za-radio.za-radio--link {
  line-height: 52px;
  line-height: var(--button-height-lg);
}

.za-radio-group--button.za-radio-group--lg .za-radio * + span {
  margin-left: calc(20px / 2);
  margin-left: calc(var(--button-padding-h-lg) / 2);
}

.za-radio-group--button.za-radio-group--md .za-radio {
  padding: 0 16px;
  padding: 0 var(--button-padding-h-md);
  font-size: 16px;
  font-size: var(--button-font-size-md);
  height: 44px;
  height: var(--button-height-md);
  line-height: 1.5;
}
.za-radio-group--button.za-radio-group--md .za-radio.za-radio--circle {
  width: 44px;
  width: var(--button-height-md);
  padding: 0;
}

.za-radio-group--button.za-radio-group--md .za-radio .za-icon {
  font-size: 22px;
  font-size: var(--button-icon-size-md);
}
.za-radio-group--button.za-radio-group--md .za-radio.za-radio--link {
  line-height: 44px;
  line-height: var(--button-height-md);
}

.za-radio-group--button.za-radio-group--md .za-radio * + span {
  margin-left: calc(16px / 2);
  margin-left: calc(var(--button-padding-h-md) / 2);
}

.za-radio-group--button.za-radio-group--sm .za-radio {
  padding: 0 12px;
  padding: 0 var(--button-padding-h-sm);
  font-size: 14px;
  font-size: var(--button-font-size-sm);
  height: 36px;
  height: var(--button-height-sm);
  line-height: 1.5;
}
.za-radio-group--button.za-radio-group--sm .za-radio.za-radio--circle {
  width: 36px;
  width: var(--button-height-sm);
  padding: 0;
}

.za-radio-group--button.za-radio-group--sm .za-radio .za-icon {
  font-size: 18px;
  font-size: var(--button-icon-size-sm);
}
.za-radio-group--button.za-radio-group--sm .za-radio.za-radio--link {
  line-height: 36px;
  line-height: var(--button-height-sm);
}

.za-radio-group--button.za-radio-group--sm .za-radio * + span {
  margin-left: calc(12px / 2);
  margin-left: calc(var(--button-padding-h-sm) / 2);
}

.za-radio-group--button.za-radio-group--xs .za-radio {
  padding: 0 8px;
  padding: 0 var(--button-padding-h-xs);
  font-size: 12px;
  font-size: var(--button-font-size-xs);
  height: 28px;
  height: var(--button-height-xs);
  line-height: 1.5;
}
.za-radio-group--button.za-radio-group--xs .za-radio.za-radio--circle {
  width: 28px;
  width: var(--button-height-xs);
  padding: 0;
}

.za-radio-group--button.za-radio-group--xs .za-radio .za-icon {
  font-size: 16px;
  font-size: var(--button-icon-size-xs);
}
.za-radio-group--button.za-radio-group--xs .za-radio.za-radio--link {
  line-height: 28px;
  line-height: var(--button-height-xs);
}

.za-radio-group--button.za-radio-group--xs .za-radio * + span {
  margin-left: calc(8px / 2);
  margin-left: calc(var(--button-padding-h-xs) / 2);
}

.za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked, .za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked:hover {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}
.za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked:active {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}
.za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked.za-radio--disabled, .za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked.za-radio--disabled:active, .za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked.za-radio--disabled:hover, .za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked.za-radio--disabled:focus, .za-radio-group--button.za-radio-group--ghost .za-radio.za-radio--checked.za-radio--disabled:visited {
  background-color: transparent;
  border-color: hsl(156, 100%, 36.9%);
  border-color: var(--theme-primary);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
  cursor: not-allowed;
}

.za-radio-group--button.za-radio-group--compact .za-radio:not(:last-child) {
  margin-right: -1PX;
}
.za-radio-group--button.za-radio-group--compact .za-radio:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.za-radio-group--button.za-radio-group--compact .za-radio:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.za-radio-group--button.za-radio-group--compact .za-radio:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.za-radio-group--button.za-radio-group--compact .za-radio.za-radio--checked {
  z-index: 1;
}

.za-radio-group--cell {
  display: block;
  overflow: visible;
}
.za-radio-group--cell .za-radio-group__inner {
  margin-right: 0;
  margin-bottom: 0;
}

.za-radio-group--cell .za-radio {
  position: static;
  margin-right: 0;
  margin-bottom: 0;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-search-bar {
  background: #fff;
  background: var(--search-bar-background);
}
.za-search-bar__form {
  height: 44px;
  margin: 0 15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.za-search-bar__content {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 8px;
  height: 28px;
  background: #f2f2f2;
  background: var(--search-bar-inner-background);
  overflow: hidden;
}
.za-search-bar__content .za-search-bar__icon {
  margin-right: 4px;
  font-size: 18px;
  font-size: var(--search-bar-icon-size);
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-search-bar__mock {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  font-size: 14px;
  font-size: var(--search-bar-font-size);
}
.za-search-bar__mock .za-input {
  background-color: transparent;
}
.za-search-bar__mock .za-input input::-webkit-search-cancel-button {
  display: none;
}

.za-search-bar__cancel {
  font-size: 14px;
  font-size: var(--search-bar-font-size);
  color: #343434;
  color: var(--color-text);
  margin-left: 15px;
}

.za-search-bar--focus .za-search-bar__cancel {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin-right: 0;
}

.za-search-bar--radius .za-search-bar__content {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-search-bar--round .za-search-bar__content {
  border-radius: 1000px;
  border-radius: var(--radius-round);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-select {
  width: 100%;
  height: 100%;
}
.za-select__input {
  position: relative;
  width: 100%;
  padding: 12px 0;
  line-height: 28px;
}

.za-select--placeholder .za-select__input {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-select--arrow .za-select__input {
  padding-right: 20px;
}
.za-select--arrow .za-select__input:after {
  content: "";
  position: absolute;
  width: 10px;
  width: var(--cell-arrow-length);
  height: 10px;
  height: var(--cell-arrow-length);
  top: calc(15px + 3px);
  top: calc(var(--cell-padding-v) + 3px);
  right: 2px;
  border-right: 2px solid #bcbcbc;
  border-right: 2px solid var(--cell-arrow-color);
  border-bottom: 2px solid #bcbcbc;
  border-bottom: 2px solid var(--cell-arrow-color);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.za-select--disabled .za-select__input {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-slider {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.za-slider__content {
  position: relative;
  margin: calc(24px / 2) calc(24px / 2);
  margin: calc(var(--slider-handle-size) / 2) calc(var(--slider-handle-size) / 2);
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-slider__line {
  position: relative;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 2px;
  height: var(--slider-line-height);
  background-color: #dcdcdc;
  background-color: var(--slider-line-background);
}
.za-slider__line .za-slider__line__bg {
  height: 100%;
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}

.za-slider__line .za-slider__line__dot {
  position: absolute;
  width: 6px;
  width: var(--slider-line-dot-width);
  height: 6px;
  height: var(--slider-line-dot-width);
  margin-top: calc((6px + 2px) / -2);
  margin-top: calc((var(--slider-line-dot-width) + var(--slider-line-height)) / -2);
  margin-left: calc(6px / -2);
  margin-left: calc(var(--slider-line-dot-width) / -2);
  background-color: #fff;
  background-color: var(--slider-line-dot-color);
  border: 1px solid #dcdcdc;
  border: 1px solid var(--slider-line-background);
  border-radius: 50%;
  cursor: pointer;
}
.za-slider__line .za-slider__line__dot--active {
  border: 1px solid hsl(156, 100%, 36.9%);
  border: 1px solid var(--theme-primary);
}

.za-slider__handle {
  position: absolute;
  left: 0;
  margin-top: calc((24px + 2px) / -2);
  margin-top: calc((var(--slider-handle-size) + var(--slider-line-height)) / -2);
  margin-left: calc(24px / -2);
  margin-left: calc(var(--slider-handle-size) / -2);
  width: 24px;
  width: var(--slider-handle-size);
  height: 24px;
  height: var(--slider-handle-size);
  border-radius: 50%;
  background-color: #fff;
  background-color: var(--slider-handle-background);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: var(--slider-handle-shadow);
          box-shadow: var(--slider-handle-shadow);
  -ms-touch-action: none;
      touch-action: none;
}
.za-slider__handle .za-tooltip {
  display: block;
}
.za-slider__handle .za-slider__handle__shadow {
  width: 24px;
  width: var(--slider-handle-size);
  height: 24px;
  height: var(--slider-handle-size);
}

.za-slider__marks {
  position: absolute;
  font-size: 15px;
  font-size: var(--slider-mark-size);
  top: 10px;
  left: 0;
  right: 0;
  color: #dcdcdc;
  color: var(--slider-line-background);
}
.za-slider__marks .za-slider__mark {
  position: absolute;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-slider--marked .za-slider__content {
  margin: calc(16px / 2) calc(16px / 2);
  margin: calc(var(--slider-handle-size-small) / 2) calc(var(--slider-handle-size-small) / 2);
}

.za-slider--marked .za-slider__handle {
  width: 16px;
  width: var(--slider-handle-size-small);
  height: 16px;
  height: var(--slider-handle-size-small);
  margin-top: calc((16px + 2px) / -2);
  margin-top: calc((var(--slider-handle-size-small) + var(--slider-line-height)) / -2);
  margin-left: calc(16px / -2);
  margin-left: calc(var(--slider-handle-size-small) / -2);
}
.za-slider--marked .za-slider__handle .za-slider__handle__shadow {
  width: 16px;
  width: var(--slider-handle-size-small);
  height: 16px;
  height: var(--slider-handle-size-small);
}

.za-slider--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-slider--vertical {
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  width: auto;
  height: 100%;
  -webkit-align-items: initial;
      -ms-flex-align: initial;
          align-items: initial;
}
.za-slider--vertical .za-slider__content {
  margin: calc(24px / 2) calc(24px / 2);
  margin: calc(var(--slider-handle-size) / 2) calc(var(--slider-handle-size) / 2);
}

.za-slider--vertical .za-slider__line {
  width: 2px;
  width: var(--slider-vertical-line-width);
  position: absolute;
  top: 0;
  height: 100%;
}
.za-slider--vertical .za-slider__line .za-slider__line__bg {
  position: absolute;
  bottom: 0;
  width: 2px;
  width: var(--slider-line-height);
}

.za-slider--vertical .za-slider__line__dot {
  margin-bottom: calc(6px / -2);
  margin-bottom: calc(var(--slider-line-dot-width) / -2);
  margin-left: calc((6px - 2px) / -2);
  margin-left: calc((var(--slider-line-dot-width) - var(--slider-line-height)) / -2);
}

.za-slider--vertical .za-slider__handle {
  margin-top: calc(24px / -2);
  margin-top: calc(var(--slider-handle-size) / -2);
}

.za-slider--vertical.za-slider--marked .za-slider__content {
  margin: calc(16px / 2) calc(16px / 2);
  margin: calc(var(--slider-handle-size-small) / 2) calc(var(--slider-handle-size-small) / 2);
}

.za-slider--vertical.za-slider--marked .za-slider__marks {
  top: 0;
  left: 15px;
  width: 20px;
  height: 100%;
}

.za-slider--vertical.za-slider--marked .za-slider__mark {
  -webkit-transform: translateY(50%);
      -ms-transform: translateY(50%);
          transform: translateY(50%);
}

.za-slider--vertical.za-slider--marked .za-slider__handle {
  margin-top: 0;
  margin-bottom: calc(16px / -2);
  margin-bottom: calc(var(--slider-handle-size-small) / -2);
  margin-left: calc((16px - 2px) / -2);
  margin-left: calc((var(--slider-handle-size-small) - var(--slider-line-height)) / -2);
  width: 16px;
  width: var(--slider-handle-size-small);
  height: 16px;
  height: var(--slider-handle-size-small);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-stepper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.za-stepper .za-input input {
  height: 100%;
  text-align: center;
}
.za-stepper .za-input--disabled input {
  color: #343434;
  color: var(--color-text);
  -webkit-text-fill-color: #343434;
  -webkit-text-fill-color: var(--color-text);
  cursor: auto;
}
.za-stepper .za-input--disabled .za-input__virtual-input {
  color: #343434;
  color: var(--color-text);
  -webkit-text-fill-color: #343434;
  -webkit-text-fill-color: var(--color-text);
  cursor: auto;
}

.za-stepper .za-input__content {
  height: 100%;
}

.za-stepper .za-input__virtual-input {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.za-stepper__sub, .za-stepper__plus {
  width: 28px;
  width: var(--stepper-height-md);
  padding: 0;
}
.za-stepper__sub .za-icon, .za-stepper__plus .za-icon {
  font-size: 12px;
  font-size: var(--stepper-icon-font-size-md);
}

.za-stepper__input {
  position: relative;
  width: 50px;
  width: var(--stepper-input-width-md);
  height: 28px;
  height: var(--stepper-height-md);
  margin: 0 4px;
  margin: 0 var(--stepper-input-margin-h-md);
  padding: 0 8px;
  padding: 0 var(--stepper-input-padding-h-md);
  font-size: 14px;
  font-size: var(--stepper-input-font-size-md);
  background: #fff;
  background: var(--stepper-input-background);
  color: #343434;
  color: var(--color-text);
  text-align: center;
  border: 1px solid #dcdcdc;
  border: 1px solid var(--border-color);
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
}

.za-stepper--disabled .za-stepper__input {
  color: #bcbcbc;
  color: var(--stepper-input-disabled);
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

.za-stepper--radius .za-stepper__input {
  border-radius: 4px;
  border-radius: var(--radius-md);
}

.za-stepper--circle .za-stepper__input {
  border-radius: 28px;
  border-radius: var(--stepper-height-md);
}

.za-stepper--lg .za-stepper__input {
  width: 60px;
  width: var(--stepper-input-width-lg);
  height: 36px;
  height: var(--stepper-height-lg);
  margin: 0 4px;
  margin: 0 var(--stepper-input-margin-h-lg);
  padding: 0 8px;
  padding: 0 var(--stepper-input-padding-h-lg);
  font-size: 16px;
  font-size: var(--stepper-input-font-size-lg);
}

.za-stepper--lg .za-stepper__sub, .za-stepper--lg .za-stepper__plus {
  width: 36px;
  width: var(--stepper-height-lg);
  padding: 0;
}
.za-stepper--lg .za-stepper__sub .za-icon, .za-stepper--lg .za-stepper__plus .za-icon {
  font-size: 14px;
  font-size: var(--stepper-icon-font-size-lg);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-swipe-action {
  position: relative;
}
.za-swipe-action__content {
  position: relative;
}

.za-swipe-action__actions {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
}
.za-swipe-action__actions--left {
  left: 0;
}

.za-swipe-action__actions--right {
  right: 0;
}

.za-swipe-action .za-button {
  font-size: 14px;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-switch__input {
  position: relative;
  width: 53px;
  width: var(--switch-width);
  height: 32px;
  height: var(--switch-height);
  border: calc((32px - 26px) / 2) solid #dcdcdc;
  border: calc((var(--switch-height) - var(--switch-handle-size)) / 2) solid var(--switch-border-color);
  border-radius: 1000px;
  border-radius: var(--radius-round);
  background-color: #dcdcdc;
  background-color: var(--switch-background);
  vertical-align: middle;
  -webkit-transition: background-color 0.1s, border 0.1s;
  transition: background-color 0.1s, border 0.1s;
  outline: 0;
  -webkit-appearance: none;
}
.za-switch__input:before, .za-switch__input:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  height: var(--switch-handle-size);
  border-radius: 1000px;
  border-radius: var(--radius-round);
}
.za-switch__input:before {
  width: calc(53px - 32px + 26px);
  width: calc(var(--switch-width) - var(--switch-height) + var(--switch-handle-size));
  background-color: #dcdcdc;
  background-color: var(--switch-background);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1), -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}
.za-switch__input:after {
  width: 26px;
  width: var(--switch-handle-size);
  background-color: #fff;
  background-color: var(--switch-handle-background);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35), -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}
.za-switch__input:checked {
  border: calc((32px - 26px) / 2) solid hsl(156, 100%, 36.9%);
  border: calc((var(--switch-height) - var(--switch-handle-size)) / 2) solid var(--theme-primary);
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}
.za-switch__input:checked:before {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.za-switch__input:checked:after {
  -webkit-transform: translateX(calc(53px - 32px));
      -ms-transform: translateX(calc(53px - 32px));
          transform: translateX(calc(53px - 32px));
  -webkit-transform: translateX(calc(var(--switch-width) - var(--switch-height)));
      -ms-transform: translateX(calc(var(--switch-width) - var(--switch-height)));
          transform: translateX(calc(var(--switch-width) - var(--switch-height)));
}

.za-switch--disabled .za-switch__input {
  cursor: not-allowed;
  opacity: 0.5;
  opacity: var(--opacity-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-tabs__header {
  position: relative;
  overflow: hidden;
}

.za-tabs__tablist {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.za-tabs__panel {
  display: none;
  position: relative;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  height: 100%;
}
.za-tabs__panel--active {
  display: block;
}

.za-tabs__tab {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  text-align: center;
  color: #343434;
  color: var(--color-text);
  font-size: 16px;
  font-size: var(--tabs-item-font-size);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.za-tabs__tab--disabled {
  color: #bcbcbc;
  color: var(--color-text-disabled);
  cursor: not-allowed;
}

.za-tabs__line {
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}
.za-tabs__line__inner {
  display: block;
  margin: auto;
  height: 100%;
  background-color: hsl(156, 100%, 36.9%);
  background-color: var(--theme-primary);
}

.za-tabs--scroll .za-tabs__tablist::-webkit-scrollbar {
  display: none;
}

.za-tabs--horizontal .za-tabs__header {
  width: 100%;
}
.za-tabs--horizontal .za-tabs__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-tabs--horizontal .za-tabs__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-tabs--horizontal .za-tabs__tablist {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  height: var(--tabs-height);
  line-height: 45px;
  line-height: var(--tabs-height);
}

.za-tabs--horizontal .za-tabs__line {
  height: 2px;
  height: var(--tabs-line-height);
  left: 0;
  bottom: 0;
}

.za-tabs--horizontal.za-tabs--scroll .za-tabs__tablist {
  overflow-x: auto;
}

.za-tabs--horizontal.za-tabs--scroll .za-tabs__tab {
  overflow: visible;
  padding: 0 20px;
  padding: 0 var(--tabs-item-horizontal-padding);
}

.za-tabs--vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.za-tabs--vertical .za-tabs__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-right: 1PX solid #dcdcdc;
  border-right: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-tabs--vertical .za-tabs__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-tabs--vertical .za-tabs__tablist {
  height: 100%;
}

.za-tabs--vertical .za-tabs__tab {
  text-align: center;
  padding: 12px 20px;
  padding: var(--tabs-item-vertical-padding) var(--tabs-item-horizontal-padding);
}

.za-tabs--vertical .za-tabs__line {
  width: 2px;
  width: var(--tabs-line-height);
  top: 0;
  right: 0;
}

.za-tabs--vertical .za-tabs__body {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  position: relative;
}

.za-tabs--vertical.za-tabs--scroll .za-tabs__tablist {
  overflow-y: auto;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-toast {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.za-toast__container {
  position: relative;
  padding: 12px 16px;
  padding: var(--padding-v-md) var(--padding-h-md);
  border-radius: 6px;
  border-radius: var(--toast-border-radius);
  background: rgba(0, 0, 0, 0.8);
  background: var(--toast-background);
  color: #fff;
  color: var(--toast-color);
  text-align: center;
  font-size: 13px;
  font-size: var(--toast-font-size);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-tooltip {
  padding: 6px 8px;
  padding: var(--tooltip-padding-v) var(--tooltip-padding-h);
  background-color: rgba(0, 0, 0, 0.8);
  background-color: var(--tooltip-background);
  border-radius: 4px;
  font-size: 12px;
  font-size: var(--tooltip-font-size);
  color: #fff;
  color: var(--tooltip-color);
  z-index: 1700;
  z-index: var(--zindex-tooltip);
}
.za-tooltip[x-placement^=right] {
  margin-left: 10px;
}
.za-tooltip[x-placement^=right] .za-tooltip__arrow {
  left: -4px;
  border-width: 4px 4px 4px 0;
  border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size) var(--tooltip-arrow-size) 0;
  border-right-color: rgba(0, 0, 0, 0.8);
  border-right-color: var(--tooltip-background);
}
.za-tooltip[x-placement^=top] {
  margin-bottom: 10px;
}
.za-tooltip[x-placement^=top] .za-tooltip__arrow {
  bottom: -4px;
  border-width: 4px 4px 0;
  border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size) 0;
  border-top-color: rgba(0, 0, 0, 0.8);
  border-top-color: var(--tooltip-background);
}
.za-tooltip[x-placement^=bottom] {
  margin-top: 10px;
}
.za-tooltip[x-placement^=bottom] .za-tooltip__arrow {
  top: -4px;
  border-width: 0 4px 4px 4px;
  border-width: 0 var(--tooltip-arrow-size) var(--tooltip-arrow-size) var(--tooltip-arrow-size);
  border-bottom-color: rgba(0, 0, 0, 0.8);
  border-bottom-color: var(--tooltip-background);
}
.za-tooltip[x-placement^=left] {
  margin-right: 10px;
}
.za-tooltip[x-placement^=left] .za-tooltip__arrow {
  right: -4px;
  border-width: 4px 0 4px 4px;
  border-width: var(--tooltip-arrow-size) 0 var(--tooltip-arrow-size) var(--tooltip-arrow-size);
  border-left-color: rgba(0, 0, 0, 0.8);
  border-left-color: var(--tooltip-background);
}
.za-tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-wheel {
  display: block;
  position: relative;
  width: 100%;
  height: calc(5 * 34px);
  height: calc(var(--wheel-item-rows) * var(--wheel-item-height));
  overflow: hidden;
}
.za-wheel__content {
  margin-top: calc(34px * 2);
  margin-top: calc(var(--wheel-item-height) * 2);
}

.za-wheel__item {
  text-align: center;
  font-size: 18px;
  font-size: var(--wheel-item-font-size);
  line-height: 34px;
  line-height: var(--wheel-item-height);
  height: 34px;
  height: var(--wheel-item-height);
  color: #343434;
  color: var(--wheel-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.za-wheel__item--disabled {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-tab-bar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: #fff;
  background: var(--tabbar-background);
  height: 50px;
  height: var(--tabbar-height);
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-property: height bottom;
  transition-property: height bottom;
  overflow: hidden;
}
.za-tab-bar--hidden {
  bottom: -50px;
  bottom: var(--tabbar-hidden-bottom);
  height: 0;
}

.za-tab-bar--active .za-tab-bar__title, .za-tab-bar--active .za-tab-bar__icon {
  color: hsl(156, 100%, 36.9%);
  color: var(--tabbar-item-active-color);
}

.za-tab-bar__item {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.za-tab-bar__item .za-tab-bar__title {
  font-size: 12px;
  font-size: var(--tabbar-item-font-size);
  line-height: 1;
  margin-top: 2px;
}

.za-tab-bar__item .za-tab-bar__icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  font-size: 18px;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-image-preview {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
}
.za-image-preview__title {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  color: #fff;
  color: var(--color-text-inverse);
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-size: var(--font-size-lg);
}

.za-image-preview__content {
  height: 100%;
  width: 100%;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.za-image-preview__item {
  text-align: center;
  position: relative;
  overflow: auto;
}
.za-image-preview__item img {
  width: 100%;
}

.za-image-preview__item__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.za-image-preview__footer {
  text-align: center;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 16px;
  padding: 0 var(--padding-v-lg);
}

.za-image-preview__index {
  text-align: right;
  color: #fff;
  color: var(--color-text-inverse);
  padding: 16px;
  padding: var(--padding-v-lg);
  font-size: 16px;
  font-size: var(--font-size-lg);
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.za-image-preview .za-popup {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.za-image-preview .za-popup__wrapper {
  padding: 0;
}

.za-image-preview__loading {
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
  margin-right: var(--padding-h-xs);
}

.za-image-preview__origin__button {
  background: #2d2d2d;
  background: var(--image-preview-origin-button-background);
  border: none;
  border-radius: 2px;
  border-radius: var(--radius-xs);
  font-size: 12px;
  font-size: var(--font-size-sm);
  display: inline-block;
  color: #fff;
  color: var(--color-text-inverse);
  padding: 0 12px;
  padding: 0 var(--padding-h-sm);
  height: 28px;
  height: var(--button-height-xs);
  line-height: 28px;
  line-height: var(--button-height-xs);
}

.za-image-preview .za-carousel {
  height: 100%;
  width: 100%;
}
.za-image-preview .za-carousel__items {
  height: 100%;
}
.za-image-preview .za-mask {
  background-color: black;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-pinch-zoom {
  overflow: hidden;
  position: relative;
  -ms-touch-action: auto;
      touch-action: auto;
  min-height: 100%;
}
.za-pinch-zoom > * {
  -webkit-transform: translate(var(--x), var(--y)) scale(var(--scale));
      -ms-transform: translate(var(--x), var(--y)) scale(var(--scale));
          transform: translate(var(--x), var(--y)) scale(var(--scale));
  will-change: transform;
}

/**
 * block define
 * @param $name     block name
 * @examples
 *
 *    @include b(button) { ... }
 *    =>
 *    .za-button { ... }
 *
 *
 *    @include b(checkbox-group) { ... }
 *    =>
 *    .za-checkbox-group { ... }
 */
/**
 * element define
 * @param $list     list of element names
 * @examples
 *
 *    @include b(button) {
 *      @include e(icon) { ... }
 *    }
 *    =>
 *    .za-button__icon { ... }
 *
 *
 *    @include b(dialog) {
 *      @include e(header, footer) { ... }
 *    }
 *    =>
 *    .za-dialog__header, .za-dialog__footer { ... }
 */
/**
 * modifier define
 * @param $modifier     modifier name
 * @examples
 *
 *    @include b(button) {
 *      @include m(danger) { ... }
 *    }
 *    =>
 *    .za-button--danger { ... }
 *
 *
 *    @include b(button) {
 *      @include m(danger) {
 *        @include e(content) { ... }
 *      }
 *    }
 *    =>
 *    .za-button--danger .za-button__content { ... }
 *
 *
 *    @include b(button) {
 *      @include e(content) {
 *        @include m(danger) { ... }
 *      }
 *    }
 *    =>
 *    ..za-button__content--danger { ... }
 */
.za-stackpicker {
  width: 100%;
  height: 100%;
  background: #fff;
  background: var(--stack-picker-background);
}
.za-stackpicker__input {
  width: 100%;
  padding: 15px 0;
  padding: var(--cell-padding-v) 0;
}

.za-stackpicker__placeholder {
  color: #a9a9a9;
  color: var(--color-text-placeholder);
}

.za-stackpicker--disabled {
  color: #bcbcbc;
  color: var(--color-text-disabled);
}

.za-stackpicker__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  width: 100%;
  height: 45px;
  height: var(--picker-header-height);
  line-height: 45px;
  line-height: var(--picker-header-height);
  font-size: 16px;
  font-size: var(--picker-header-font-size);
  background: #f7f7f7;
  background: var(--picker-header-background);
}
.za-stackpicker__header:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top: 1PX solid #dcdcdc;
  border-top: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-stackpicker__header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}

.za-stackpicker__cancel, .za-stackpicker__submit {
  padding: 0 20px;
  padding: 0 var(--padding-h-lg);
  cursor: pointer;
}

.za-stackpicker__cancel {
  color: #909090;
  color: var(--color-text-caption);
}

.za-stackpicker__submit {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}

.za-stackpicker__column-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.za-stackpicker__column-group .za-stackpicker__item {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  text-align: center;
}

.za-stackpicker__crumbs {
  position: relative;
  padding: 15px 20px;
  padding: var(--cell-padding-v) var(--padding-h-lg);
  line-height: 24px;
  line-height: var(--stack-picker-crumbs-line-height);
  font-size: 14px;
  font-size: var(--font-size-md);
  color: #909090;
  color: var(--color-text-caption);
}
.za-stackpicker__crumbs:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-stackpicker__crumbs:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-stackpicker__crumbs p {
  margin: 0;
}

.za-stackpicker__crumbs-error {
  color: #ff5050;
  color: var(--stack-picker-error);
}

.za-stackpicker__group {
  position: relative;
  height: calc(8 * 35px);
  height: calc(8 * var(--stack-picker-stack-item-height));
  overflow: hidden;
}

.za-stackpicker__stack-column {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.za-stackpicker__stack-column--hidden ~ .za-stackpicker__stack-column {
  -webkit-transform: translate(100%, 0);
      -ms-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.za-stackpicker__stack-column--hidden .za-stackpicker__item {
  padding-left: 20px;
  padding-left: var(--stack-picker-padding);
}

.za-stackpicker__stack-column:nth-child(1) {
  z-index: 1001;
}
.za-stackpicker__stack-column:nth-child(1) .za-stackpicker__stack-column-wrapper {
  z-index: 3;
}
.za-stackpicker__stack-column:nth-child(2) {
  z-index: 2002;
}
.za-stackpicker__stack-column:nth-child(2) .za-stackpicker__stack-column-wrapper {
  z-index: 5;
  left: 60px;
  left: var(--stack-picker-left);
}
.za-stackpicker__stack-column:nth-child(3) {
  z-index: 3003;
  left: calc(60px * (3 - 2));
  left: calc(var(--stack-picker-left) * (3 - 2));
}
.za-stackpicker__stack-column:nth-child(3) .za-stackpicker__stack-column-wrapper {
  z-index: 7;
  left: 60px;
  left: var(--stack-picker-left);
}
.za-stackpicker__stack-column:nth-child(4) {
  z-index: 4004;
  left: calc(60px * (4 - 2));
  left: calc(var(--stack-picker-left) * (4 - 2));
}
.za-stackpicker__stack-column:nth-child(4) .za-stackpicker__stack-column-wrapper {
  z-index: 9;
  left: 60px;
  left: var(--stack-picker-left);
}
.za-stackpicker__stack-column:nth-child(5) {
  z-index: 5005;
  left: calc(60px * (5 - 2));
  left: calc(var(--stack-picker-left) * (5 - 2));
}
.za-stackpicker__stack-column:nth-child(5) .za-stackpicker__stack-column-wrapper {
  z-index: 11;
  left: 60px;
  left: var(--stack-picker-left);
}
.za-stackpicker__stack-column:not(:last-child) .za-stackpicker__stack-column__item {
  padding-right: 40px;
}
.za-stackpicker__stack-column:not(:last-child) .za-stackpicker__stack-column__item:before {
  position: absolute;
  right: 15px;
  top: 50%;
  content: "";
  width: 9px;
  height: 9px;
  margin-top: -4px;
  border-right: 2px solid #c7c7cc;
  border-top: 2px solid #c7c7cc;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.za-stackpicker__stack-column:first-child:last-child .za-stackpicker__stack-column__item, .za-stackpicker__stack-column:nth-child(2):last-child .za-stackpicker__stack-column__item {
  padding-left: 20px;
  padding-left: var(--stack-picker-padding);
}
.za-stackpicker__stack-column-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: calc(20px * -1);
  right: calc(var(--stack-picker-padding) * -1);
  bottom: 0;
  padding-right: 20px;
  padding-right: var(--stack-picker-padding);
  overflow-y: scroll;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
          box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  -webkit-box-shadow: var(--stack-picker-shadow);
          box-shadow: var(--stack-picker-shadow);
  background: #fff;
  background: var(--stack-picker-background);
}
.za-stackpicker__stack-column-item {
  position: relative;
  padding: 0 10px;
  line-height: 35px;
  line-height: var(--stack-picker-stack-item-height);
  font-size: 16px;
  font-size: var(--stack-picker-stack-item-font-size);
  color: #343434;
  color: var(--color-text);
  -webkit-transition: padding-left 0.4s;
  transition: padding-left 0.4s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.za-stackpicker__stack-column-item:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
  border-bottom: 1PX solid #dcdcdc;
  border-bottom: 1PX solid var(--border-color);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .za-stackpicker__stack-column-item:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    border-radius: 0;
  }
}
.za-stackpicker__stack-column-item--active {
  color: hsl(156, 100%, 36.9%);
  color: var(--theme-primary);
}